
/*
 * @Descripttion: 
 * @version: 
 * @Author: liangqidong
 * @Date: 2024-11-07 17:17:55
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2024-12-04 15:45:11
 */
export default {
  tmj: 'MSPACE',
  operation:{
    Operation: 'Operation',
    Add: 'Add',
    Edit: 'Edit',
    Delete: 'Delete',
    Search: 'Search',
    Reset: 'Reset',
    Save: 'Save',
    Cancel: 'Cancel',
    Confirm: 'Confirm',
    Close: 'Close',
    Detail: 'Detail',
    Export: 'Export',
    Import: 'Import',
    Upload: 'Upload',
    Download: 'Download',
    Create: 'Create',
    Hint: 'Hint',
    Preview: 'Preview',
    Check: 'Check',
    Selcet: 'Selcet',
  },

  login: {
    inputUserName: 'Please Input UserName',
    inputPassword: 'Please Input Password',
    userName: 'UserName',
    password: 'Password',
    login: 'login',
    loginSuccess:'login successfully',
    languageChange: 'Language',
    accountLogin: 'Account',
    QRcodeLogin: 'QR Code',
    readAndAgreement: 'I have read and agree to the',
    userAgreement: 'User Agreement',
    and:' and ',
    privacyProtection: 'Privacy Protection', 
    jumpToScanPage: 'Please click to go to the login page and scan the code',
    checkDogle: 'Please check that the dongle is correctly inserted',
    readProtocol: 'Please read the agreement',
    accountExpired: "The account has expired. Please contact the administrator",
    logoutMessage: 'logout successfully!'
  },
  dict:{
    PendingExecution: "Pending Execution",
    InExecution: "In Execution",
    Completed: "Completed",
    PendingApproval: 'Pending Approval',
    NoApprovalRequired: "No Approval Required",
    Rejected: "Rejected",
    Launch: "Launch",
    Approve: "Approve",
    Execute: "Execute",
    Pass: "Pass",
    NoPass: 'No Pass',
    NoOperation: "NoOperation",
  },
  layout:{
    SwitchMap: 'Switch Map',
    NoSetOrigin: 'The origin has not been set',
    setSuccess: "successfully set",
    selectAtLeastThree : 'Please select at least three points',
    stere: "Stere",
    ExcavatedSquare: 'Volume of excavated square',
    fillSquare: "Volume of fill",
    flyIntoOrigin: "Fly into the origin",
    settingOrigin: 'Setting the origin',
    horizontalMeasurement: "Horizontal Measurement",
    verticalMeasurement : "Vertical Measurement",
    areaMeasurement: "Area Measurement",
    volumeMeasurement: "Volume Measurement",
    measurement: "Measurement",
    view: "View",
    onboard: "Onboard",
    apicalView: "Apical View",
    overlook: "Over Look",
    northArrow: "North Arrow",
    humanPerspective: "Human Perspective",
    fullScreen: "Full Screen",
    toolbar: "Toolbar",
    
  },
  header:{
    signalValue: 'Signal Value',
    ParachuteIsNormal: "Parachute Is Normal",
    ParachuteEnabled: "Parachute Enabled",
    ControllerOutline : "Controller Outline",
    parachuteDisconnected: 'Parachute Disconnected',
    parachuteFailure: 'Parachute Failure',
    parachuteInAlert: 'Parachute In Alert',
    parachuteOutAlert: 'Parachute Out Alert',
    parachuteOut: 'Parachute Out',
    uavGPSInformation: 'UAV GPS Information',
    StarSearchNumber: 'Star Search Number',
    RockerOnline: "Rocker Online",
    InfieldAuthority: 'Infield Authority',
    FieldAuthority: "Field Authority",
    MessageCenter: 'Message Center',
    NotificationOfTask: "Notification Of Task",
    AIWarningTips: "AI Warning Tips",
    SearchByTaskName: "Please input task name",
    To: "To",
    startDate: 'Start Date',
    endDate: 'End Date',
    messageType: "Message Type",
    query: "Query",
    messageTime: 'Message Time',
    serialNumber: "Serial Number",
    viewAlerts: "View Alerts",
    warningTime: 'Warning Time',
    rangeOfWarning: "Range Of Warning",
    unit: "Unit",
    detectionValue: "Detection Value",
    earlyWarningGas: "Early Warning Gas",
    latitudeAndLongitudeAddress: "Latitude And Longitude Address",
    AIRecognitionType: 'AI Recognition Type',
    locationSearch: 'Location Search',
    remark: 'Remark',
    pleaseInputRemark: 'Please Input Remark',
  },
  home:{
    home: 'Home',
    flightApplication: 'Flight Application',
    flightManagement: 'Flight Management',
    industryApplication: "Tndustry Application",
    resourceManagement: "Resource Management",
    threeDimensionalApplications: "3D Applications",
    mainPlatform: "Main Platform",
    taskManagement: "Task Management",    
    takeOverMessage: 'Please take over the UAV first, and then operate the UAV operation',
    select: 'Select',
    pleaseSelect: 'Please Select',
    CancelledOperation: 'Cancelled Operation',
    noPermissionLogout: 'You are not logged in, do you want to go to the login page ?',
    no: 'No',
    flying: 'Flying',
    electricQuantity: "Electric Quantity",
    flightSpeed: 'Flight Speed',
    flightAltitude: 'Flight Altitude',
    flightMileage: 'Flight Mileage',
    flightTime: 'Flight Time',
    altitude: 'Altitude',
    startingDistance: 'Starting Distance',
    climbRate: 'Climb Rate',
    GroundSpeed: 'Ground Speed',
    NumberOfSatellites: 'Number Of Satellites',
    Voltage: 'Voltage',
    Battery: 'Battery',
    FlightMode: 'Flight Mode',
    uav: 'UAV',
    SelectedUAV: 'Selected UAV',
    flightPositioning: 'Flight Positioning',
    flightPath: 'Flight Path',
    pauseFlight: 'Pause Flight',
    keyToReturn: 'Key To Return',
    safeLanding: 'Safe Landing',
    guidanceFlight: 'Guidance Flight',
    automatic: 'Automatic',
    manual: 'Manual',
    takeover: 'Take Over',
    takeoverUAV:'Take Over UAV',
    takeOverSuccess: "Successfully taken over ", 
    takeOverOrNot: 'Take-over or not ',
    exitTakeOver: 'Exit Take Over',
    HaveBeenTakenOver: "Have Been Taken Over ",
    isExitTakeOver: "Whether to exit takeover ",
    SafetyConfirmation: "Safety Confirmation",
    returnFlightMode: 'Return Flight Mode',
    GPSMode:'GPS Mode',
    flightRouteMode: 'Flight Route Mode',
    fourSplitScreen: 'Four Split Screen',
    nineSplitScreen: 'Nine Split Screen',
    uavInfomation: 'UAV Infomation',
    downloadCenter: 'Download Center',
    customerService: 'Customer Service',
    systemVideo: 'System Video',
    backgroundManagement: 'Background Management',
    exit: 'Exit',
    demonstration: 'Demonstration',
    hello: 'hello ,',
    noWatchStepHint: "Are you sure you're gonna skip this before you watch the video?",
    longitude: 'Longitude',
    latitude: 'Latitude',
    height: 'Height',
    direction: 'Direction',
    speed: 'Speed',
    allocationSetting: 'Allocation Setting',
    longitudeAndLatitude: "Longitude And Latitude",
    connected: "Connected",
    定点模式: 'fixed Point Mode',
    保持模式: 'Hold on Mode',
    跟随模式: 'Following Mode',
    定高模式: 'Fixed height mode',
    手动模式: 'Manual mode',
    特技模式: 'Trick mode',
    板外模式: 'Off board mode',
    自稳模式: 'Stabilize Mode',
    起飞模式: 'Takeoff mode',
    离线状态: 'Offline status'
  },
  navibar: {
    uavApplication: 'UAV Application',
    nestApplicaition: 'Nest Applicaition', 
    uavList:'UAV List',
    RouteManagement: "Route Management",
    ProjectList: "Project List",
    PilotList: "Pilot List",
    ServiceNetworkList: "Service Network List",
    TrainingNetworkList: "Training Network List",
    DisplayNetworkList: "Display Network List",
    RunningProjectList: "Running Project List",
    ThreeDApplications: "3D Applications",
    uavMission: "UAV Mission",
    AerieTask: "Aerie Task",
    FlightLog: "Flight Log",
    AirspaceManagement: "Airspace Management",
    EagleNestList: "Eagle's Nest list",
    WarningRecord: "Warning Record",  
    BaseMap: "Base Map",
    MultiplePhaseContrast: "Multiple Phase Contrast",
    RegionalAnalysis: "Regional Analysis",
    PanoramicDisplay: "Panoramic Display",
    Total: 'Total',
    unit: 'Unit',
  },
  
  flightApplication:{
    uavName: 'UAV Name',
    inpueUavName: "Please enter the name of the uav/organization",
    libraryOfTasks:'Library of tasks',
    TaskName: "Task Name",
    TaskType: "Task Type",
    TaskDetail: 'Task Detail',
    TaskFlow: "Task Flow",
    TaskDescription: 'Task Description',
    ResponsibleAgency: "Responsible Agency",
    ExecutionStatus: "Execution Status",
    ExecutionTime: "Execution Time",
    pleaseSelectExecutionTime: "please Select Execution Time",
    CreateTime: 'Create Time',
    EndTask: "End Task",
    ValidityPeriod: "Validity Period",
    StartAutomatic: "Start Automatic",
    loading: "Loading",
    inputNestName: "Please enter Eagle's Nest name/Institution name",
    pleaseInputAirspaceName: "Please enter the airspace name",
    New: "New",
    Airspace: "Airspace",
    newAirspace: 'New Airspace',
    airspaceName: 'Airspace Name',
    airspaceType: 'Airspace Type',
    airspaceStatus: "Airspace Status",
    pleaseSelectAirspaceType: "Please Select Airspace Type",
    pleaseSelectOrganization: "Please Select Organization",
    pleaseDrawRegion: "Draw a region or enter a geographic location",
    pleaseSelectStatus: "Please select status",
    pleaseSelectColor: "Please select a color",
    limitHeight: 'Limit Height',
    pleaseInputLimitHeight: "Please enter the limit height",
    pleaseSelectFirstPoint: "Please click to select the first point",
    pleaseSelectSecondPoint: "Please select the second point",
    PleaseSelectAtLeastThreePoints: "Please select at least three points",
    route: 'Route',
    routeId: "Route Id",
    newRoutes: 'New Routes',
    inputRouteName: 'Please enter the route name', 
    routeName: 'Route Name',
    pleaseSelectRoute: 'Please select a route name',
    numberOfWaypoints: 'Waypoints Number',
    lengthOfRoute: "length Of Route",
    saveTime: "Save Time",
    scheduledTime: "Scheduled Time",
    supportRouteFormat: "Support upload json,kml format",
    airSpaceHiddenMsg: "This airspace is hidden and cannot be displayed!",
    HangarList: "Hangar List",
    inboard: "inboard",
    outboard: "inboard",
    mounting: "Mounting",
    recognition: "Recognition",
    VisualGallery: "Visual Gallery",
    NestOfMachine: "Nest Of Machine",
    OneClickecognition: "One-click Recognition",
    MonitoringAndDefense: "Monitoring And Defense",

    "查看详情": "View Details",
    "企业信息": "Enterprise Information",
    "联系人": "Linkman",
    "应用类型": "Application Type",
    "联系人电话": "Phone Number",
    "应用": "Application",
    "联系人邮箱": "Contact Email",
    "版本": "version",
    "项目定位": "Project Positioning",
    "备注描述": "Description of Remarks",  
    "鹰巢未在线": "Eagle Nest is not online",
    "内部应用": "Internal Applications",
    "外部应用": "External Applications",
    "推广应用": "Promoted Application",
    "标准版": "Standard Edition",
    "专业版": "Professional Edition",
    "旗舰版": "Ultimate Edition",
    "接管鹰巢": "Take over the Eyrie",
    "流配置未配置": "Stream configuration is not configured",
    "无人机离线中": "The uav is offline",
    "一键任务": 'One-click task',

    "本月": "This Month",
    "本周": "This Week",
    "今日": "Today",
    "日期": "Date",
    "统计模式": "Statistical mode",    
    "总任务数量": "Total number of tasks",
    "台": "台",
    "总飞行架次": "Total sorties flown",
    "次": "times",
    "总飞行里程": "Total miles traveled",
    "总飞行时长": "Total flight duration",
    "总照片数量": "Total photo count",
    "张": "pieces",
    "飞行架次号": "The Flying sortie", 
    "飞行时长": "Flight Duration",
    "ai预警总次数": "Total number of ai alerts",    
    "所属任务": "Owning Task",
    "图片": 'Picture',
    "AI图片": "AI Images",
    "记录回放": "Record Playback",
    "飞行报告": "Flight Report",
    "时间": "Time",
    "名称": "Name",
    "视频": "Video",
    "架次": "Sortie",   
    "航线规划": "Route planning", 
    "航线编辑": "Edit Route",
    "请选择责任机构": "Please select the responsible authority",
    "默认高度": "Default Height",
    "请输入默认高度":"Please enter the default height!",
    "默认速度": "Default Speed",
    "默认偏航角": "Default Yaw Angle",
    "默认俯仰角": "Default Pitch Angle",
    "绘制航点": "Plot Waypoint",       
    "规划航线": "Route Planning",
    "导入航线": "Lead-in Route",
    "支持上传json格式航线": "Support uploading json format routes",
    "终点降落": "Terminal Landing",
    "否": "No",
    "是": "Yes",
    "绘制方式": "Drawing Mode",
    "选择航线": "Route Selection",  
    "保存航线": "Save the route",
    "添加成功": "Add successfully",
    "修改成功": "Modify Successfully",
    "修改失败": "Change failed",
    "操作成功": "Operate Successfully",
    "删除成功": "Delete Successfully",
    "删除失败": "Fail to delete",
    "识别成功": "Identify Successfully",
    "截屏成功": "screenshot Successfully",
    "截屏失败": "Failed screen capture",
    "出错了": "Something went wrong",
    "启用": "Enable",
    "停用": "Disable",
    "坐标内容": "Coordinate",
    "所属机构": "Affiliated Org",
    "请选择所属机构": "please select the Affiliated Org",
    "色值": "Color Value",
    "绘制多边形": "Draw Polygon",
    "绘制圆形": "Draw Circle",
    "电子围栏": "Electronic Fence",
    "禁飞区": "No-fly Zone",
    "限高区": "Height District",

    "拍照": "Take Photos",
    "录屏": "Screen Recording",
    "截屏": "Screen Shot",
    "AI预警": "AI Early Warning",
    "选择算法":"Select Algorithm",
    "请选择算法": "Please Select Algorithm",
    "抽帧间隔": "Frame Extraction Interval",
    "标准": "Standard",
    "自定义": "User-defined",
    "抽帧频率": "Frame pumping frequency",
    "秒/次": "s/times",
    "停止布防": "Stop Deployment",
    "编辑布防": "Edit Defense",
    "确认布防": "Confirmation deployment",
    "气体监测预警": "Gas monitoring and early warning",
    "水位监测预警": "Water level monitoring and warning",
    "喷火器": "flamethrower",
    "保险": "Insurance",
    "喷火": "Sting-out",
    "出舱": "Descent",
    "入舱": "Stow Down",
    "开始充电": "Start charging",
    "结束充电": "End of charge",
    "预热": "Preheat",
    "休眠": "Dormancy",
    "退出流程": "Exiting the process",
    "运行监测": "Operational monitoring",
    "筛选": "filtrate",
    "飞行报告导出": "Report export",

    "飞行操作": "Flight Operations",
    "操作提示": "Please take over the UAV first and then operate the UAV operation",
    "一键起飞": "One-click take-off",
    "运行日志": "Running Log",
    "键盘": "Keyboard",
    "内场控制": "Infield Control",
    "继续飞行": "Keep Flying",


  },
  resourceManagement:{
    resourceType: "Resource Type",
    resourceName: "Resource Name",
    resourceAddress: "Resource Address",
  },
  industryApplication:{
    pleaseInputProjectName: 'Please input project name',
    projectName: 'Project Name',
    enter: 'Enter',
    noData: "No Data",
  },
  
  threeDApplication: {
    layer:  "Layer",
    label: "Label",
    tag: "Tag",
    editTag: "Edit Tag",
    SplitScreenContrast: "Split Screen Contrast",
    PleaseInputTagName: 'Please input tag name',
    PleaseInputLabelName: "Please input label name",
    SelectLabelToDelete: "Please select the tag you want to delete!",
    preview: "Preview",
    screenshot: 'Screenshot',
    addDotAnnotation: "Add Dot Annotation",
    addLineAnnotation: "Add Line Annotation",
    addFaceAnnotation: "Add Face Annotation",
    pleaseSelectLabel: 'Please Select Label',
    all: "All",
    level: "Level",
    pleaseSelectLevel: 'Please Select Level',
    addTag: 'Add Tag',
    typeManagement: 'TypeManage',
    import: 'Import',
    export: 'Export',
    tagTypeManagement: 'Tag Type Management',
    showOrHide: 'show/hide',
    labelType: 'Label Type',
    inputName: "Input Name",
    pleaseInputTagType: "Please Input Tag Type", 
  },

  taskMangement: {
    "常态任务": "Normal Task",
    "定时任务": "Timed Task",
    "周期任务": "Periodic Task",
    "创建任务": 'Create Task',
    "任务状态": "Task Status",
    "责任单位": "Responsible Org",
    "重新发起": "relaunch",
    "审批": "audit",
    "请检查任务状态是否为待审批": "Check whether the task status is pending approval",
    "请检查任务状态是否为待执行": "Check whether the task is in the Pending state",
    "确定执行当前任务": "Confirm to execute the current task?",
    "请检查当前无人机是否在线": "Mission sending failed, please check whether the current drone is online!",
    "任务审批":"Task Approval",
    "驳回": "reject",
    "驳回原因": "Cause of rejection",
    "请输入驳回原因": "Please enter the reason for rejection",
    "审批成功": "Approval successful",
    "基本信息": "Basic Information",
    "正常或通过": "Normal or pass",
    "异常或驳回": "Exception or reject",
    "请输入任务名称": "Please enter task name",
    "请选择任务流程": "Please select task flow",
    "任务场景": "Task Scenario",
    "请选择任务场景": "Please select the task scenario",
    "请选择执行状态": "Please select the execution state",

    "开始时间": "Start Time",
    "请选择预计开始时间": "Please select an estimated start time",
    "定时执行时间": "Timed execution time",
    "周期类型": "Period Type",
    "每日": "Per Diem",
    "每周": "Per Week",
    "每月": "Per Month",
    "周期日历": "Periodic Calendar",
    "请选择周期日历": "Please select a periodic calendar",
    "请选择周期类型": "Please select a cycle type",
    "有效期限": "Valid Period",

    "任务流程详情": "Task flow details",
    "流程节点名称": "Process node name",
    "节点功能": "Node function",
    "指派操作员": "Assigned operator",
    "指派类型": "Assignment type",
    "操作状态": "Operation Status",
    "实际操作员": "Actual operator",
    "实际操作员机构": "Actual operator mechanism",
    "操作时间": "Operation Time",
    "任务创建": "Create Task",
    "获取设备列表失败": "Failed to get the device list",
    "最后一天": "Last Day",
    "任务发起": "Task initiation",
    "派出所审批": "Approval by the police station",
    "任务执行": "Task execution",
    "任务编辑": "Task editing",
    "指定用户": "Designated User",
    "指定机构": "Designated Agency",
    "周一": "Monday",
    "周二": "Tuesday",
    "周三": "Wednesday",
    "周四": "Thursday",
    "周五": "Friday",
    "周六": "Saturday",
    "周日": "Sunday",
    "历史回放": "Historical replay",
    "AI预警数": "Number of AI alerts",
    "取证上传图片数": "Number of forensic uploaded images",

  }

}
<template>
  <div class="MMCData">
    <div class="left-box">
      <ThrottleGauge />
    </div>
    <div class="middle-box">
      <div class="content-box">
        <div class="content-box-top">
          <div class="content-box-select">
            <span class="label">{{$t('home.uav')}}:</span>
            <span class="uav-label" :title="$uav.hardId">
              {{ $uav.hardId }}
            </span>
            <!-- <el-select v-model="$uav" clearable disabled>
              <el-option
                v-for="item in uavOnlineData"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select> -->
          </div>
        </div>
        <div class="content-box-middle">
          <div class="content-box-select">
            <span class="label">{{$t('flightApplication.route')}}:</span>
            <el-select
              v-model="airLine"
              clearable
              :disabled="!!taskDetails"
              @change="showRoute"
            >
              <el-option
                v-for="item in uavRouteList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </div>
          <div class="content-value">
            <div class="operate">
              <div>{{$t('operation.Operation')}}</div>
              <div>
                {{ openOperate ? "🔓" : "🔒" }}
                <el-switch
                  v-model="openOperate"
                  active-color="#50ABFF"
                  inactive-color="#5C6881"
                />
              </div>
              <div
                class="operate-item"
                :class="{ active: openOperate ? true : false }"
                @click="takeoffEvent"
              >
                <img
                  style="width: 15px; height: 15px; vertical-align: middle"
                  src="~@/assets/uavApplications/drone-operation/一键起飞.png"
                  alt
                />
                一键起飞
              </div>
              <div
                v-if="subplatform"
                class="operate-item"
                :class="{ active: openOperate ? true : false }"
                @click="wsControl('任务结束')"
              >
                <img
                  style="width: 15px; height: 15px; vertical-align: middle"
                  src="~@/assets/uavApplications/drone-operation/一键返航.png"
                  alt
                />
                任务结束
              </div>
              <div
                v-else
                class="operate-item"
                :class="{ active: openOperate ? true : false }"
                @click="wsControl('安全降落')"
              >
                <img
                  style="width: 15px; height: 15px; vertical-align: middle"
                  src="~@/assets/uavApplications/drone-operation/一键返航.png"
                  alt
                />
                安全降落
              </div>
              <!-- v-if="$uav && $uav.network === 1" -->
              <div
                class="operate-item"
                :class="{ active: openOperate ? true : false }"
                @click="wsControl('模式')"
              >
                <img
                  v-if="flyMode == 'auto'"
                  style="width: 15px; height: 15px; vertical-align: middle"
                  src="~@/assets/uavApplications/drone-operation/auto.png"
                  alt
                />
                <img
                  v-if="flyMode == 'manual'"
                  style="width: 15px; height: 15px; vertical-align: middle"
                  src="~@/assets/uavApplications/drone-operation/手动.png"
                  alt
                />
                {{ flyMode == "auto" ? "自动" : "" }}
                {{ flyMode == "manual" ? "手动" : "" }}
              </div>
            </div>
          </div>
        </div>

        <div class="content-box-bottom">
          <div class="bottom-left">
            <div class="onLike" />
            <span>{{$t('home.connected')}}</span>
          </div>
          <div class="bottom-middle">
            <div class="item-box">
              <div class="top">
                {{
                  this.uavRealTimeData.gps.relativeAlt
                    ? this.uavRealTimeData.gps.relativeAlt.toFixed(0)
                    : "-"
                }}
              </div>
              <div class="middle">{{$t('home.flightAltitude')}}</div>
              <div class="bottom">m</div>
            </div>
            <div class="item-box">
              <div class="top">
                {{
                  this.uavRealTimeData.gps.absoluteAlt
                    ? this.uavRealTimeData.gps.absoluteAlt.toFixed(0)
                    : "-"
                }}
              </div>
              <div class="middle">{{$t('home.altitude')}}</div>
              <div class="bottom">m</div>
            </div>
            <div class="item-box">
              <div class="top">
                {{
                  this.uavRealTimeData.flyDistance
                    ? this.uavRealTimeData.flyDistance.toFixed(0)
                    : "-"
                }}
              </div>
              <div class="middle">{{$t('home.flightMileage')}}</div>
              <div class="bottom">m</div>
            </div>
            <div class="item-box">
              <div class="top">
                {{
                  this.uavRealTimeData.distanceToHome
                    ? this.uavRealTimeData.distanceToHome.toFixed(0)
                    : "-"
                }}
              </div>
              <div class="middle">{{$t('home.startingDistance')}}</div>
              <div class="bottom">m</div>
            </div>
            <div class="item-box">
              <div class="top">
                {{
                  this.uavRealTimeData.flyTime
                    ? Moment(this.uavRealTimeData.flyTime).format("m:s")
                    : "-"
                }}
              </div>
              <div class="middle">{{$t('home.flightTime')}}</div>
              <div class="bottom">min</div>
            </div>
            <div class="item-box">
              <div class="top">
                {{
                  this.uavRealTimeData.groundSpeed
                    ? this.uavRealTimeData.groundSpeed.toFixed(0)
                    : "-"
                }}
              </div>
              <div class="middle">{{$t('home.flightSpeed')}}</div>
              <div class="bottom">m/s</div>
            </div>
            <div class="item-box">
              <div class="top">
                {{
                  this.uavRealTimeData.velocityZ
                    ? this.uavRealTimeData.velocityZ.toFixed(0)
                    : "-"
                }}
              </div>
              <div class="middle">{{$t('home.climbRate')}}</div>
              <div class="bottom">m/s</div>
            </div>
            <div class="item-box">
              <div class="top">
                {{ longitude }}
              </div>
              <div class="middle">{{$t('home.longitude')}}</div>
              <div class="bottom">-</div>
            </div>
            <div class="item-box">
              <div class="top">
                {{ latitude }}
              </div>
              <div class="middle">{{$t('home.latitude')}}</div>
              <div class="bottom">-</div>
            </div>
          </div>
          <div class="bottom-right">
            <div class="cell-box">
              <div class="cell" />
              <div class="label-cell">{{$t('home.Battery')}}</div>
              <div class="cell-label">
                {{
                  this.uavRealTimeData.chargeRemaining
                    ? this.uavRealTimeData.chargeRemaining.toFixed(0)
                    : "-"
                }}
                <span class="cell-label-more">%</span>
              </div>
              <div class="cell-label">
                {{
                  this.uavRealTimeData.voltage
                    ? this.uavRealTimeData.voltage.toFixed(0)
                    : "-"
                }}
                <span class="cell-label-more">V</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="right-box">
      <Obstacle />
    </div>
  </div>
</template>

<script>
import Obstacle from "./components/obstacle";
import ThrottleGauge from "./components/throttleGauge";
import { Control_API, AirLine, flightTaskAPI, Tg } from "@/api";
import Moment from "moment";
import { mapState } from "vuex";
let positions = [];
let handler = null;
const data_srouce = null;
const airline_entitys = []; // 航线实体
let point_entity = null;
let label_entity = null;
import { Utils } from "@/utils/cesium";
import {
  deviceStatusDictionary,
  eagleTaskTypeDictionary,
} from "@/utils/dictionary";
export default {
  components: { Obstacle, ThrottleGauge },
  props: {
    taskDetails: {
      typeof: Object,
      default: () => {},
    },
    propOpenOperate: {
      typeof: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
      openOperate: false, // 打开/关闭操作
      airLine: null, // 航线选择
      uavOnlineData: [],
      uavId: null,
      uavRouteList: [],
      Moment,
      subplatform: localStorage.getItem("subplatform-permission"),
    };
  },
  computed: {
    ...mapState(["userInfo"]),
    ...mapState("uavApplicationsEagleNest", ["currentEagle"]),
    // 无人机选择
    $uav() {
      return this.$store.state.uavApplications.uav;
    },
    longitude() {
      const locationCoordinate3D =
        this.$store.state.uavApplications.uavRealTimeData.locationCoordinate3D;
      if (locationCoordinate3D.longitude) {
        const longitude = parseFloat(locationCoordinate3D.longitude);
        if (!isNaN(longitude)) {
          return longitude.toFixed(3);
        }
      }
      return "-";
    },
    latitude() {
      const locationCoordinate3D =
        this.$store.state.uavApplications.uavRealTimeData.locationCoordinate3D;
      if (locationCoordinate3D.latitude) {
        const latitude = parseFloat(locationCoordinate3D.latitude);
        if (!isNaN(latitude)) {
          return latitude.toFixed(3);
        }
      }
      return "-";
    },
    // 实时数据
    uavRealTimeData() {
      return this.$store.state.uavApplications.uavRealTimeData;
    },
    airlineData() {
      return this.$store.state.uavApplications.airlineData;
    },
    routeId() {
      return this.$store.state.uavApplications.routeId;
    },
    fly_control_unlock: {
      get() {
        return this.$store.state.uavApplications.fly_control_unlock;
      },
      set(val) {
        this.$store.commit("uavApplications/setState", {
          key: "fly_control_unlock",
          value: val,
        });
      },
    },
    // 飞行模式
    flyMode: {
      get() {
        return this.$store.state.uavApplications.flyMode;
      },
      set(val) {
        this.$store.commit("uavApplications/setState", {
          key: "flyMode",
          value: val,
        });
      },
    },
    mqttDataSet() {
      return this.$store.state.MMCMQTT?.dataSet?.[this.currentEagle?.hardId];
    },
    getCurrentEagleTaskType() {
      return eagleTaskTypeDictionary.find(
        (v) => v.value === this.taskDetails.type
      )?.label;
    },
  },
  watch: {
    fly_control_unlock(val) {
      if (val) {
        this.openOperate = true;
      }
    },
    async routeId(val) {
      if (val) {
        this.airLine = val;
        await this.getRouteList();
        this.showRoute(val);
      } else {
        this.airLine = null;
        this.clear_airline_entitys();
      }
    },
    airLine(val) {
      if (!val) {
        this.clear_airline_entitys();
      }
      const find = this.uavRouteList.find((item) => item.id === this.airLine);
      this.$store.commit("uavApplications/setState", {
        key: "airlineData",
        value: find,
      });
    },
    propOpenOperate(val) {
      this.openOperate = val;
    },
  },
  created() {
    this.getUavList();
    this.getRouteList();
  },
  beforeDestroy() {
    this.clear_airline_entitys();
  },
  mounted() {},
  methods: {
    // 无人机接管更新状态
    async updateTakeOver() {
      await Control_API.updateTakeOver(this.$uav.id);
    },
    onChangeUav(e) {
      this.uavOnlineData.forEach((val) => {
        if (val.id == e) {
          this.$store.commit("uavApplications/setState", {
            key: "uav",
            value: val,
          });
        }
      });
    },
    // //提示
    // messageBox() {
    //   this.$msgbox({
    //     title: this.$t('operation.Hint'),
    //     message: "请先接管无人机，然后再操作无人机作业",
    //     showCancelButton: true,
    //     confirmButtonText: this.$t('operation.Confirm'),
    //     cancelButtonText: this.$t('operation.Cancel'),
    //     beforeClose: (action, instance, done) => {
    //       done();
    //     },
    //   }).then((action) => {});
    // },
    messageBox({ title, message, confirmButtonText, cancelButtonText, fun }) {
      if (!title || !message) return console.log("请输入提示标题以及提示内容");
      const value = {
        title,
        message,
        center: true,
      };
      if (confirmButtonText) {
        value.confirmButtonText = confirmButtonText;
      }
      if (cancelButtonText) {
        value.cancelButtonText = this.$t('operation.Cancel');
        value.showCancelButton = true;
      }
      this.$msgbox({
        ...value,
        beforeClose: (action, instance, done) => {
          done();
        },
      }).then((action) => {
        fun && fun();
      });
    },
    // 清除航线
    clear_airline_entitys() {
      const viewer = window.viewer;
      airline_entitys.forEach((item) => {
        viewer.entities.remove(item);
      });

      if (handler) {
        handler && handler.destroy() && (handler = null);
        handler = null;
      }
    },

    showRoute(lineId) {
      this.clear_airline_entitys();
      const lineData = this.uavRouteList.filter((res) => {
        return res.id === lineId;
      });
      if (lineData.length > 0) {
        if (lineData[0].content) {
          const polyline = [JSON.parse(lineData[0].content)];
          let pointPositions = [];
          const label_arr = [];
          const _this = this;
          if (polyline.length > 0) {
            polyline[0].content.forEach((item, index) => {
              console.log(item.alt, " item.alt item.alt");
              pointPositions.push(item.longitude, item.latitude, item.alt);
              label_arr.push(
                Cesium.Cartesian3.fromDegrees(
                  item.longitude,
                  item.latitude,
                  item.alt
                )
              );
              point_entity = viewer.entities.add({
                position: Cesium.Cartesian3.fromDegrees(
                  item.longitude,
                  item.latitude,
                  item.alt
                ),
                name: "show_airline_point",
                point: {
                  pixelSize: 20,
                  color: Cesium.Color.RED,
                  color: Cesium.Color.fromCssColorString("#1890FF"),
                  // fillColor: Cesium.Color.RED,
                  outlineWidth: 2,
                  outlineColor: Cesium.Color.fromCssColorString("#FFF"),
                  // heightReference: Cesium.HeightReference.RELATIVE_TO_GROUND,
                },
                label: {
                  // text: String(item.altitude) + 'm',
                  text: String(index + 1),
                  scale: 0.5,

                  font: "bold 24px Microsoft YaHei",
                  // fillColor: Cesium.Color.BLUE,
                  fillColor: Cesium.Color.fromCssColorString("#fff"),
                  horizontalOrigin: Cesium.VerticalOrigin.CENTER,
                  verticalOrigin: Cesium.VerticalOrigin.CENTER,
                  disableDepthTestDistance: Number.POSITIVE_INFINITY,
                  outlineWidth: 0,
                  // showBackground: true,
                  // backgroundColor: Cesium.Color.fromCssColorString("#1890FF")
                  // pixelOffset: new Cesium.Cartesian2(25, -10),
                  // backgroundPadding: new Cesium.Cartesian2(10, 10)
                },
                id: JSON.stringify({
                  ...item,
                  type: "flight_point",
                  index: index,
                }),
              });
              airline_entitys.push(point_entity);
              if (label_arr.length > 1 && !this.isEditting) {
                const before = label_arr[label_arr.length - 2];
                const after = label_arr[label_arr.length - 1];
                _this.create_label(before, after);
              }
            });

            pointPositions =
              Cesium.Cartesian3.fromDegreesArrayHeights(pointPositions);

            const redLine = viewer.entities.add({
              name: "Red line on terrain",
              polyline: {
                positions: new Cesium.CallbackProperty(() => {
                  return pointPositions;
                }, false),
                width: 4,
                // clampToGround: true,
                // zIndex: -99,
                material: Cesium.Color.fromCssColorString("#1890FF"),
                // material: ({
                //   // material: Cesium.Color.fromCssColorString('#1890FF'),
                //   color: Cesium.Color.RED,
                //   outlineWidth: 2,
                //   outlineColor: Cesium.Color.fromCssColorString('#FFF')
                // })
                // // clampToGround: true,
                // material: new Cesium.PolylineOutlineMaterialProperty({
                //   color: Cesium.Color.fromCssColorString('#1890FF'),

                //   // outlineWidth: 2,
                //   // outlineColor: Cesium.Color.fromCssColorString('#FFF'),
                // }),
              },
            });
            positions = pointPositions;
            viewer.flyTo(redLine);
            airline_entitys.push(redLine);
          }
        }
      }
    },
    // 两点距离
    create_label(before, after) {
      const viewer = window.viewer;
      const before_wgs84 = Utils.transformCartesian2WGS84(before);
      const after_wgs84 = Utils.transformCartesian2WGS84(after);

      const center_lng = (before_wgs84.lng + after_wgs84.lng) / 2;
      const cetner_lat = (before_wgs84.lat + after_wgs84.lat) / 2;
      const alt = (after_wgs84.alt + before_wgs84.alt) / 2;

      const position = Utils.transformWGS842Cartesian({
        lng: center_lng,
        lat: cetner_lat,
        alt: alt,
      });

      const text = `${Cesium.Cartesian3.distance(before, after).toFixed(2)} m`;

      label_entity = viewer.entities.add({
        id: `label_${before}`,
        position: position,
        label: {
          text: text,
          scale: 0.5,
          font: "bold 30px Microsoft YaHei",
          fillColor: Cesium.Color.fromCssColorString("#fff"),
          horizontalOrigin: Cesium.VerticalOrigin.CENTER,
          verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
          disableDepthTestDistance: Number.POSITIVE_INFINITY,
          showBackground: true,
          backgroundPadding: new Cesium.Cartesian2(10, 10),
        },
      });
      airline_entitys.push(label_entity);
    },
    // 一键起飞（区分无人机跟鹰巢）
    takeoffEvent() {
      if (this.currentEagle) {
        if (!this.$uav.currentOperator) {
          const messageValue = {
            title: this.$t('home.SafetyConfirmation'),
            message: `请确认是否接管${this.$uav.name}且进行一键起飞操作？`,
            confirmButtonText: this.$t('operation.Confirm'),
            cancelButtonText: this.$t('operation.Cancel'),
            fun: () => {
              Tg.setUavControlOn({
                force: false,
                id: this.$uav.id,
                uid: this.userInfo.id,
              }).then((res) => {
                if (res.code === 200) {
                  this.$message.success(`${this.$t('home.takeOverSuccess')}${this.$uav.name}`);
                  this.$store.commit("uavApplications/setState", {
                    key: "uav",
                    value: {
                      ...this.$uav,
                      currentOperator: this.userInfo.id,
                    },
                  });
                  this.eagleTakeOffClick();
                }
              });
            },
          };
          this.messageBox(messageValue);
        } else if (this.userInfo.id !== this.$uav.currentOperator) {
          // 不是本账号接管的
          const messageValue = {
            title: this.$t('operation.Hint'),
            message: `当前选择的无人机正在被其他用户接管，请稍后操作或联系接管人？`,
            confirmButtonText: "好的,知道了",
          };
          // 不是本账号接管的无人机
          this.messageBox(messageValue);
        } else {
          this.$confirm(
            `请确认是否进行一键起飞操作${
              this.taskDetails ? "且执行任务" : ""
            }？`,
            this.$t('home.SafetyConfirmation'),
            {
              cancelButtonText: this.$t('operation.Cancel'),
              confirmButtonText: this.$t('operation.Confirm'),
              customClass: "uav_controlPane",
              showClose: false,
            }
          ).then(() => {
            this.eagleTakeOffClick();
          });
        }
      } else {
        this.$confirm("请确认是否进行一键起飞操作?", this.$t('home.SafetyConfirmation'), {
          cancelButtonText: this.$t('operation.Cancel'),
          confirmButtonText: this.$t('operation.Confirm'),
          customClass: "uav_controlPane",
          showClose: false,
        }).then(async () => {
          this.wsControl("一键起飞");
        });
      }
    },
    // 鹰巢一键起飞
    eagleTakeOffClick() {
      if (!this.airLine) {
        return this.$message.warning("请先选择航线!");
      }
      // 当前机库状态是否空闲
      if ([0, 8].includes(this.mqttDataSet["2065"].data.processStatus)) {
        // 判断是否有任务
        if (this.taskDetails) {
          // 更新任务状态
          flightTaskAPI
            .editFlight({
              ...this.taskDetails,
              changeSchedul: false,
              deviceHardId: this.$uav.hardId,
              taskStatus: 1,
            })
            .then((res) => {
              if (res.code === 200) {
                // 获取架次号
                Control_API.getFlightSortic({
                  taskId: this.taskDetails.id,
                  deviceHardId: this.$uav.hardId,
                }).then((flightSorticRes) => {
                  this.eagleWsTakeOff(flightSorticRes.data);
                });
              }
            });
        } else {
          this.eagleWsTakeOff();
        }
      } else {
        // 获取当前机库状态
        const statusItem = deviceStatusDictionary.find(
          (item) => item.value === this.mqttDataSet["2065"].data.processStatus
        );
        this.$message.warning(statusItem?.label || "");
      }
    },
    // 鹰巢一键起飞指令
    eagleWsTakeOff(flightSortiesID) {
      const that = this;
      // 获取航线
      AirLine.routeDetail({
        id: this.airLine,
      }).then((routeRes) => {
        const flightObj = JSON.parse(routeRes.data.content);
        const waypointList = flightObj.content.map((v) => ({
          altitude: v.alt,
          coordinate: {
            latitude: v.latitude,
            longitude: v.longitude,
          },
          frame: 3,
          stay: 0.0,
          speed: v.speed,
          waypointActions: v.actions,
        }));
        this.$store.dispatch("MMCMQTT/publish", {
          topic: "PROCESS/OBTAIN/" + this.currentEagle.hardId,
          data: {
            cmdControlType: 100002,
            uavDeviceId: this.$uav.hardId,
            wayLineObj: {
              taskId: this.taskDetails?.id || undefined,
              flightSortiesID,
              autoFlightSpeed: flightObj.line.baseSpeed,
              waypointList,
              finishedAction: "GO_HOME",
              headingMode: "AUTO",
              isExitMissionOnRCSignalLostEnabled: true,
              maxFlightSpeed: 12,
            },
          },
          callback() {
            if (that.taskDetails) {
              // 手动鹰巢绑定任务
              that.$store.commit("uavApplicationsEagleNest/setState", {
                key: "currentEagle",
                value: {
                  ...that.currentEagle,
                  uav: {
                    ...that.currentEagle.uav,
                    taskId: that.taskDetails.id,
                    taskType: that.getCurrentEagleTaskType,
                  },
                },
              });
              that.$bus.$emit("updateTaskLibraryList", {
                type: 0,
                taskStatusList: [1],
              });
            }
            that.$message.success(this.$t('flightApplication.操作成功'));
          },
        });
      });
    },
    wsControl(type) {
      if (!this.openOperate) {
        return;
      }
      if (type === "一键起飞") {
        if (
          this.taskDetails &&
          this.taskDetails.taskStatus === 0 &&
          !this.taskDetails.executeButton
        ) {
          return this.$emit("oneClickTask", this.taskDetails);
        } else if (
          this.taskDetails &&
          this.taskDetails.taskStatus === 0 &&
          this.taskDetails.executeButton
        ) {
          return this.$message.warning("暂无权限执行此任务");
        }
      }
      if (!this.$uav.currentOperator) {
        // 未被接管
        const messageValue = {
          title: this.$t('operation.Hint'),
          message: `请确认是否接管${this.$uav.name}？`,
          confirmButtonText: this.$t('operation.Confirm'),
          cancelButtonText: this.$t('operation.Cancel'),
          fun: async () => {
            const res = await Tg.setUavControlOn({
              force: false,
              id: this.$uav.id,
              uid: this.userInfo.id,
            });
            if (res.code === 200) {
              this.$message.success(`${this.$t('home.takeOverSuccess')}${this.$uav.name}`);
              this.$store.commit("uavApplications/setState", {
                key: "uav",
                value: {
                  ...this.$uav,
                  currentOperator: this.userInfo.id,
                },
              });
              this.operateCommand(type);
            }
          },
        };
        this.messageBox(messageValue);
      } else if (this.userInfo.id !== this.$uav.currentOperator) {
        // 不是本账号接管的
        const messageValue = {
          title: this.$t('operation.Hint'),
          message: `当前选择的无人机正在被其他用户接管，请稍后操作或联系接管人？`,
          confirmButtonText: "好的,知道了",
        };
        // 不是本账号接管的无人机
        return this.messageBox(messageValue);
      } else {
        this.operateCommand(type);
      }
    },
    // 操作指令
    operateCommand(type) {
      switch (type) {
        case "一键起飞":
          if (!this.$uav && !this.airLine) {
            this.$message.warning("请选择航线与无人机");
            return;
          } else if (!this.airLine) {
            this.$message.warning("请选择航线");
            return;
          } else if (!this.$uav) {
            this.$message.warning("请选择无人机");
            return;
          }
          this.$confirm("请确认是否进行一键起飞操作?", this.$t('home.SafetyConfirmation'), {
            cancelButtonText: this.$t('operation.Cancel'),
            confirmButtonText: this.$t('operation.Confirm'),
            customClass: "uav_controlPane",
            showClose: false,
          }).then(async () => {
            try {
              this.$store.dispatch("uavApplications/takeOff", {
                callback: (isOk) => {
                  isOk
                    ? this.$message.success("发出起飞指令成功")
                    : this.$message.warning("发出起飞指令失败");
                },
              });
            } catch (e) {
              this.$message.warning("解析航线失败");
              console.log("解析航线失败", e);
            }
          });

          break;

        case "任务结束":
          this.$confirm("请确认是否进行任务结束操作?", this.$t('home.SafetyConfirmation'), {
            cancelButtonText: this.$t('operation.Cancel'),
            confirmButtonText: this.$t('operation.Confirm'),
            customClass: "uav_controlPane",
            showClose: false,
          }).then(async () => {
            this.endTask();
          });
          break;

        case "安全降落":
          this.$confirm("请确认是否进行安全降落操作?", this.$t('home.SafetyConfirmation'), {
            cancelButtonText: this.$t('operation.Cancel'),
            confirmButtonText: this.$t('operation.Confirm'),
            customClass: "uav_controlPane",
            showClose: false,
          }).then((res) => {
            this.$store.dispatch("uavApplications/land", {
              callback: (isOk) => {
                this.$message.success("发出降落指令成功");
              },
            });
          });
          break;

        case "模式":
          if (this.$uav.network == 1) {
            if (this.flyMode === "auto") {
              this.$store.dispatch("uavApplications/modeManual", {
                callback: () => {},
              });
              this.flyMode = "manual";
            } else {
              this.$store.dispatch("uavApplications/modeAuto", {
                callback: () => {},
              });
              this.flyMode = "auto";
            }
          } else {
            if (this.flyMode === "auto") {
              this.control_ws(21);
              this.flyMode = "manual";
            } else {
              this.control_ws(22);
              this.flyMode = "auto";
            }
          }
          break;
      }
      this.updateTakeOver();
    },
    async getUavList() {
      const res = await Control_API.getUavDataList({ online: 1 });
      if (res.code === 200) {
        this.uavOnlineData = res.data.records;
      }
    },
    async getRouteList() {
      const res = await Control_API.getUavRouteList({
        page: 1,
        pageSize: 9999,
      });
      if (res.code === 200) {
        this.uavRouteList = res.data.records;
      }
    },
    //   任务结束
    async endTask() {
      if (!this.taskDetails || this.taskDetails.taskStatus !== 1) {
        return this.$message.warning("暂无执行任务!");
      }
      const res = await flightTaskAPI.editFlight({
        ...this.taskDetails,
        changeSchedul: false,
        deviceHardId: this.$uav.hardId,
        taskStatus: 2,
      });
      if (res.code === 200) {
        this.$message({
          showClose: true,
          type: "success",
          message: "任务结束成功",
          duration: 1000,
        });
        this.$emit("getTaskList");
        this.openOperate = false;
        this.fly_control_unlock = null;
        this.$store.commit("uavApplicationsEagleNest/setState", {
          key: "currentEagle",
          value: {
            ...this.currentEagle,
            uav: { ...this.currentEagle.uav, taskId: null },
          },
        });
      } else {
        this.$message({
          showClose: true,
          type: "error",
          message: res.msg,
          duration: 1000,
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.MMCData {
  position: fixed;
  bottom: 5%;
  left: 25vw;
  // transform: translateX(-50%);
  display: flex;

  .left-box {
    width: 127px;
    height: 127px;
    transform: translateX(50%);
    border-radius: 50%;
  }

  .middle-box {
    width: 816px;
    height: 127px;
    background: url("~@/assets/uavApplications/data-panel/content-box.png")
      no-repeat;
    background-size: contain;
    display: flex;
    justify-content: center;
    align-items: center;

    .content-box {
      width: 80%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .name {
        background: #16181b;
        border: 1px solid #233a58;
        border-radius: 4px;
        padding: 3px;
        width: 155px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        vertical-align: sub;
      }

      &-select {
        font-family: MicrosoftYaHei;
        font-size: 12px;
        color: #b3bbc5;
        letter-spacing: 0;

        .label {
          display: inline-block;
          width: 50px;
        }
        .uav-label {
          display: inline-block;
          width: 150px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          height: 18px;
          line-height: 24px;
        }
      }

      &-top {
        padding: 5px 0;
        width: 100%;
        height: 24px;
      }

      &-middle {
        width: 100%;
        height: 24px;
        display: flex;
        align-items: center;

        .content-value {
          display: flex;
          align-items: center;
          justify-content: space-around;
          flex: 1;

          .push {
            cursor: pointer;
            width: 54px;
            height: 24px;
            background: #161d2b;
            border-radius: 4px;
            font-family: MicrosoftYaHei;
            font-size: 12px;
            color: #f8f8f8;
            letter-spacing: 0;
            text-align: center;
            font-weight: 400;
            line-height: 24px;

            &:hover {
              background: #686969;
              color: #fff;
            }
          }

          .operate {
            width: 316px;
            height: 24px;
            background: #161d2b;
            border-radius: 4px;
            font-family: MicrosoftYaHei;
            font-size: 10px;
            color: #ebf5ff;
            letter-spacing: 0;
            text-align: center;
            font-weight: 400;
            line-height: 24px;
            display: flex;
            align-items: center;
            justify-content: space-around;

            .operate-item {
              cursor: not-allowed;
              color: #787f8a;

              &:hover {
                background: #686969;
                color: #fff;
              }
            }

            .active {
              cursor: pointer;
              color: #fff;
            }
          }
        }
      }

      &-bottom {
        width: 100%;
        flex: 1;
        display: flex;
        padding-top: 5px;

        .bottom-left {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          border-left: 1px solid rgba($color: #95989d, $alpha: 0.5);
          border-right: 1px solid rgba($color: #95989d, $alpha: 0.5);
          padding: 0 3px;
          width: 48px;
          height: 56px;
          font-family: MicrosoftYaHei;
          font-size: 10px;
          color: #ebf5ff;
          letter-spacing: 0;
          text-align: center;
          font-weight: 400;

          .onLike {
            width: 30px;
            height: 30px;
            background: url("~@/assets/uavApplications/data-panel/link.png")
              no-repeat;
            background-size: cover;
          }
        }

        .bottom-middle {
          display: flex;
          justify-content: space-around;
          padding: 0 15px;
          flex: 1;

          .item-box {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 48px;
            height: 56px;

            .top {
              font-family: UniDreamLED;
              font-size: 14px;
              color: #08ffff;
              letter-spacing: 0;
              text-align: center;
              font-weight: 400;
            }

            .middle {
              font-family: MicrosoftYaHei;
              font-size: 10px;
              color: #ebf5ff;
              letter-spacing: 0;
              text-align: center;
              font-weight: 400;
            }

            .bottom {
              opacity: 0.5;
              font-family: MicrosoftYaHei;
              font-size: 10px;
              color: #ebf5ff;
              letter-spacing: 0;
              font-weight: 400;
            }
          }
        }

        .bottom-right {
          border-left: 1px solid rgba($color: #95989d, $alpha: 0.5);
          border-right: 1px solid rgba($color: #95989d, $alpha: 0.5);
          width: 138px;
          height: 56px;
          display: flex;
          flex-direction: column;
          justify-content: center;

          .cell-box {
            box-sizing: border-box;
            padding: 0 10px;
            display: flex;
            align-items: center;
            justify-content: space-around;

            .label-cell {
              font-family: MicrosoftYaHei;
              font-size: 10px;
              color: #ebf5ff;
              letter-spacing: 0;
              text-align: center;
              font-weight: 400;
            }

            .cell {
              width: 22px;
              height: 36px;
              background: url("~@/assets/uavApplications/cell/BAT10.png")
                no-repeat;
              background-size: contain;
            }

            .cell-label {
              font-family: UniDreamLED;
              font-size: 16px;
              color: #08ffff;
              letter-spacing: 0;
              text-align: center;
              text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
              font-weight: 400;

              &-more {
                opacity: 0.7;
                font-family: MicrosoftYaHei;
                font-size: 10px;
                color: #ebf5ff;
                letter-spacing: 0;
                font-weight: 400;
              }
            }
          }
        }
      }
    }
  }

  .right-box {
    width: 127px;
    height: 127px;
    /* background-color: #13daf0; */
    transform: translateX(-50%);
    border-radius: 50%;
  }
}

::v-deep {
  .el-select {
    width: 163px;
    height: 24px;

    .el-input {
      &.el-input--suffix {
        height: 24px;

        .el-input__inner {
          background: #16181b;
          border: 1px solid #233a58;
          border-radius: 4px;
        }
      }

      .el-select__caret {
        line-height: 24px !important;
      }
    }
  }

  .el-switch.is-checked .el-switch__core:after {
    background-color: #d4f8ff;
  }

  .el-switch .el-switch__core:after {
    background-color: #50abff;
  }
}
</style>

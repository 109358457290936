<template>
  <div class="flight-line-list">
    <div class="search_box line">
      <!-- v-pms="['飞行航线', '航线规划']" -->
      <el-button
        v-if="permissionFn(547)"
        v-hover
        @click="showStatus = $t('operation.Add');showAdd = true;">
        {{ $t('flightApplication.newAirspace') }}
      </el-button>
      <el-input
        v-model="search.name"
        class="w223"
        :placeholder="$t('flightApplication.pleaseInputAirspaceName')"
        prefix-icon="el-icon-search"
        @change="handelSearch"
      />
    </div>
    <div class="tb-box">
      <div class="tb-hd-box">
        <div class="tb-hd">{{$t('flightApplication.airspaceName')}}</div>
        <div class="tb-hd">{{$t('flightApplication.airspaceType')}}</div>
        <div class="tb-hd">{{$t('operation.Operation')}}</div>
      </div>
      <div class="tb-bd-box">
        <div v-for="(item, index) in list" :key="index" class="tb-tr">
          <div class="td">
            {{ item.name || $t('home.no')}}
          </div>
          <div class="td">
            {{ item.typeName || $t('home.no') }}
          </div>
          <div class="td">
            <el-tooltip
              v-pms="['飞行航线', '显示']"
              effect="dark"
              :content="$t('threeDApplication.showOrHide')"
              placement="top" 
            >
              <img
                v-if="item.isEnable"
                v-hover
                src="~@/assets/home/subplatForm/icon/show.png"
                alt="#"
                style="width: 20px; height: 20px"
                @click="showOrHiddle(item)"
              />
              <img
                v-else
                v-hover
                src="~@/assets/home/subplatForm/icon/hiddle.png"
                alt="#"
                style="width: 20px; height: 20px"
                @click="showOrHiddle(item)"
              />
            </el-tooltip>
            <el-tooltip v-pms="['飞行航线', '预览']" effect="dark" :content="$t('threeDApplication.preview')" placement="top">
              <img
                v-hover
                src="~@/assets/home/subplatForm/icon/find.png"
                alt="#"
                style="width: 20px; height: 20px"
                @click="showArea(item)"
              />
            </el-tooltip>
            <el-tooltip v-pms="['飞行航线', '编辑']" effect="dark" :content="$t('operation.Edit')" placement="top">
              <img
                v-if="permissionFn(548)"
                v-hover
                src="~@/assets/home/subplatForm/icon/edit.png"
                alt="#"
                style="width: 20px; height: 20px"
                @click="goEdit(item)"
              />
            </el-tooltip>
            <el-tooltip v-pms="['飞行航线', '删除']" effect="dark" :content="$t('operation.Delete')" placement="top">
              <img
                v-if="permissionFn(549)"
                v-hover
                src="~@/assets/home/subplatForm/icon/delete.png"
                alt="#"
                style="width: 20px; height: 20px"
                @click="deleteItem(item)"
              />
            </el-tooltip>
          </div>
        </div>
      </div>
    </div>
    <div class="pagination">
      <el-pagination
        background
        layout="total,prev, pager, next"
        :total="totalPage"
        :page-size="search.size"
        :current-page.sync="search.num"
        @current-change="handleCurrentChange"
      />
    </div>
    <Add v-if="showAdd" :edit-data="editData" :show-status="showStatus" @closeAdd="closeAdd" />
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { Airspace } from '@/api';
import Add from './Add.vue';
import { permissionIds } from '@/utils/permission';

import { createPolygon } from '@/utils/CesiumFunction/CreateLabel';
let entities = [];
let positions = [];
// 绘制的空域
let polygonEntity = null;
// 所有空域
const positionsAll = {};
const entitiesAll = {};
export default {
  components: {
    Add
  },
  data() {
    return {
      list: [],
      search: {
        name: '',
        num: 1,
        size: 20
      },
      id: '',
      totalPage: null,
      showAdd: false,
      showStatus: this.$t('operation.Add'),
      editData: null
    };
  },
  async mounted() {
    viewer = window.viewer;
    // await this.showAllArea()
  },
  methods: {
    ...mapActions(['showAllArea', 'flyTo']),
    //   const param = {
    //     page: 1,
    //     pageSize: 999
    //   };
    //   await Airspace.airspaceList({ ...param }).then(resp => {
    //     resp.data.records.map(data => {
    //       entitiesAll[data.id] = []
    //       positionsAll[data.id] = []
    //       let color = data.color
    //       let text = String(data.name) || ''
    //       let coordinates = JSON.parse(data.region)
    //       console.log(coordinates.length, 'coordinates');
    //       let creatPoint = (position, index, data) => {
    //         // 添加点位
    //         let pointEntity = viewer.entities.add({
    //           position: position,
    //           point: {
    //             pixelSize: 20,
    //             color: Cesium.Color.RED,
    //             outlineColor: Cesium.Color.WHITE,
    //             outlineWidth: 2
    //           },
    //           label: {
    //             text: `${index + 1}`,
    //             scale: 0.5,
    //             font: "bold 24px Microsoft YaHei",
    //             fillColor: Cesium.Color.WHITE,
    //             horizontalOrigin: Cesium.VerticalOrigin.CENTER,
    //             verticalOrigin: Cesium.VerticalOrigin.CENTER,
    //             disableDepthTestDistance: Number.POSITIVE_INFINITY,
    //             showBackground: false,
    //             outlineWidth: 0,
    //           },
    //         });

    //         entitiesAll[data.id].push(pointEntity);
    //         positionsAll[data.id].push(position)
    //       };
    //       let createPolyline = (position, data) => {
    //         let positionList = positionsAll[data.id]
    //         // 添加连线
    //         if (positionList.length > 1) {

    //           var lineEntity = viewer.entities.add({
    //             polyline: {
    //               positions: [positionList[positionList.length - 2], position],
    //               width: 2,
    //               material: Cesium.Color.RED
    //             },
    //           });
    //           entitiesAll[data.id].push(lineEntity);
    //           // 计算两个点之间的距离
    //           let distance = Cesium.Cartesian3.distance(position, positionList[positionList.length - 2]);
    //           console.log(positionList[positionList.length - 2], '');
    //           // 计算线的中心位置
    //           let center = Cesium.Cartesian3.add(positionList[positionList.length - 2], position, new Cesium.Cartesian3());
    //           let newcenter = Cesium.Cartesian3.divideByScalar(center, 2, center);
    //           // 创建 Label 标签来显示距离
    //           var labelEntity = viewer.entities.add({
    //             position: newcenter,
    //             label: {
    //               text: distance.toFixed(2) + "m",
    //               font: "18px sans-serif",
    //               fillColor: Cesium.Color.WHITE,
    //               outlineColor: Cesium.Color.BLACK,
    //               outlineWidth: 2,
    //               style: Cesium.LabelStyle.FILL_AND_OUTLINE,
    //               pixelOffset: new Cesium.Cartesian2(0, -20)
    //             }
    //           });
    //           entitiesAll[data.id].push(labelEntity);

    //         }
    //       };
    //       let createAfterPolyline = (positions, data) => {
    //         if (positions.length > 1) {
    //           let lineEntity = viewer.entities.add({
    //             polyline: {
    //               positions: [positions[0], positions[positions.length - 1]],
    //               width: 2,
    //               material: Cesium.Color.RED
    //             },
    //           });
    //           entitiesAll[data.id].push(lineEntity);
    //           // 计算两个点之间的距离
    //           let distance = Cesium.Cartesian3.distance(positions[0], positions[positions.length - 1]);
    //           console.log('创建最后一条边-距离', distance);

    //           // 计算线的中心位置
    //           let center = Cesium.Cartesian3.add(positions[positions.length - 1], positions[0], new Cesium.Cartesian3());
    //           console.log('创建最后一条边-距离计算线的中心位置', center);

    //           let newcenter = Cesium.Cartesian3.divideByScalar(center, 2, center);
    //           // 创建 Label 标签来显示距离
    //           var labelEntity = viewer.entities.add({
    //             position: newcenter,
    //             label: {
    //               text: distance.toFixed(2) + "m",
    //               font: "18px sans-serif",
    //               fillColor: Cesium.Color.WHITE,
    //               outlineColor: Cesium.Color.BLACK,
    //               outlineWidth: 2,
    //               style: Cesium.LabelStyle.FILL_AND_OUTLINE,
    //               pixelOffset: new Cesium.Cartesian2(0, -20)
    //             }
    //           });
    //           entitiesAll[data.id].push(labelEntity);

    //         }
    //       };
    //       let createCircle = (position, data) => {
    //         let color = '#ff0000'
    //         if (data.color) {
    //           color = data.color
    //         }
    //         let radius = Cesium.Cartesian3.distance(position[0], position[1]);
    //         let center = position[0]
    //         try {
    //           polygonEntity = viewer.entities.add({
    //             position: center,
    //             label: {
    //               text: text,
    //               scale: 1.2, // 文本缩放比例
    //               verticalOrigin: Cesium.VerticalOrigin.CENTER, // 垂直方向居中对齐
    //               pixelOffset: new Cesium.Cartesian2(20, 20) // 文本偏移量
    //             },
    //             ellipse: {
    //               semiMinorAxis: radius,
    //               semiMajorAxis: radius,
    //               material: Cesium.Color.fromCssColorString(color),
    //               outline: true,
    //               outlineColor: Cesium.Color.WHITE
    //             }
    //           });
    //           // viewer.flyTo(polygonEntity)
    //           entitiesAll[data.id].push(polygonEntity)
    //         } catch (error) {
    //           console.log(error);
    //         }

    //       }
    //       // 代表矩形区域
    //       if (coordinates.length > 2) {
    //         for (let i = 0; i < coordinates.length; i++) {
    //           let position = coordinates[i]
    //           creatPoint(position, i, data)
    //           createPolyline(position, data)
    //         }
    //         polygonEntity = viewer.entities.add({
    //           polygon: {
    //             hierarchy: coordinates,
    //             material: Cesium.Color.fromCssColorString(color)
    //           }
    //         });
    //         const centerPosition = Cesium.BoundingSphere.fromPoints(coordinates).center;
    //         const labelEntity = viewer.entities.add({
    //           position: centerPosition,
    //           label: {
    //             text: text,
    //             scale: 1.2, // 文本缩放比例
    //             verticalOrigin: Cesium.VerticalOrigin.CENTER, // 垂直方向居中对齐
    //             pixelOffset: new Cesium.Cartesian2() // 文本偏移量
    //           },
    //         });
    //         createAfterPolyline(coordinates, data)
    //         entitiesAll[data.id].push(polygonEntity);
    //         entitiesAll[data.id].push(labelEntity);
    //       } else if (coordinates.length == 2) {//代表圆形区域
    //         for (let i = 0; i < coordinates.length; i++) {
    //           let position = coordinates[i]
    //           creatPoint(position, i, data)
    //           createPolyline(position, data)
    //         }
    //         createCircle(coordinates, data)
    //       }
    //     })
    //   });
    // },
    permissionFn(pid) {
      const ids = permissionIds();
      if (ids) {
        return ids.some((id) => id === pid);
      } else {
        return false;
      }
    },
    showArea(data) {
      if (data.isEnable) {
        this.flyTo({ viewer, data });
      } else {
        this.$message.warning(this.$t('flightApplication.airSpaceHiddenMsg'));
      }
      // viewer.flyTo(entitiesAll[data.id])
      // if (this.id == data.id) {
      //   if (polygonEntity) {
      //     return this.clearMarker()
      //   }
      // }

      // this.clearMarker()
      // this.id = data.id
      // let color = data.color
      // let text = String(data.name) || ''
      // let coordinates = JSON.parse(data.region)
      // // 代表矩形区域
      // if (coordinates.length > 2) {
      //   for (let i = 0; i < coordinates.length; i++) {
      //     let position = coordinates[i]
      //     this.creatPoint(position)
      //     this.createPolyline(position)
      //   }
      //   if (positions.length > 2) {
      //     polygonEntity = viewer.entities.add({
      //       polygon: {
      //         hierarchy: positions,
      //         material: Cesium.Color.fromCssColorString(color)
      //       }
      //     });
      //     const centerPosition = Cesium.BoundingSphere.fromPoints(positions).center;
      //     const labelEntity = viewer.entities.add({
      //       position: centerPosition,
      //       label: {
      //         text: text,
      //         scale: 1.2, // 文本缩放比例
      //         verticalOrigin: Cesium.VerticalOrigin.CENTER, // 垂直方向居中对齐
      //         pixelOffset: new Cesium.Cartesian2() // 文本偏移量
      //       },
      //     });
      //     this.createAfterPolyline()
      //     viewer.flyTo(polygonEntity)
      //     entities.push(polygonEntity);
      //     entities.push(labelEntity);

      //   }
      // } else if (coordinates.length == 2) {//代表圆形区域
      //   for (let i = 0; i < coordinates.length; i++) {
      //     let position = coordinates[i]
      //     this.creatPoint(position)
      //     this.createPolyline(position)
      //   }
      //   this.createCircle(data)
      // }
    },

    // label: {
    //         text: data.name||'',
    //         scale: 0.5, // 文本缩放比例
    //         verticalOrigin: Cesium.VerticalOrigin.CENTER, // 垂直方向居中对齐
    //         pixelOffset: new Cesium.Cartesian2( 20, 20) // 文本偏移量
    //       },
    // 创建圆形
    createCircle(data) {
      let color = '#ff0000';
      if (data.color) {
        color = data.color;
      }
      const text = data.name || ''; // 获取显示的文本，默认为空字符串
      const radius = Cesium.Cartesian3.distance(positions[0], positions[1]);
      const center = positions[0];
      try {
        polygonEntity = viewer.entities.add({
          position: center,
          label: {
            text: text,
            scaleByDistance: new Cesium.NearFarScalar(800, 2.0, 900, 0.6),
            scale: 0.8,
            verticalOrigin: Cesium.VerticalOrigin.CENTER, // 垂直方向居中对齐
            pixelOffset: new Cesium.Cartesian2(20, 20) // 文本偏移量
          },
          ellipse: {
            semiMinorAxis: radius,
            semiMajorAxis: radius,
            material: Cesium.Color.fromCssColorString(color),
            outline: true,
            outlineColor: Cesium.Color.WHITE
          }
        });
        viewer.flyTo(polygonEntity);
        entities.push(polygonEntity);
      } catch (error) {
        console.log(error);
      }
    },
    creatPoint(position) {
      // 添加点位
      const pointEntity = viewer.entities.add({
        position: position,
        point: {
          pixelSize: 20,
          color: Cesium.Color.RED,
          outlineColor: Cesium.Color.WHITE,
          outlineWidth: 2
        },
        label: {
          text: (positions.length + 1).toString(),
          scale: 0.5,
          font: 'bold 24px Microsoft YaHei',
          fillColor: Cesium.Color.WHITE,
          horizontalOrigin: Cesium.VerticalOrigin.CENTER,
          verticalOrigin: Cesium.VerticalOrigin.CENTER,
          disableDepthTestDistance: Number.POSITIVE_INFINITY,
          showBackground: false,
          outlineWidth: 0
        }
      });

      entities.push(pointEntity);
      positions.push(position);
    },
    createPolyline(position) {
      // 添加连线
      if (positions.length > 1) {
        var lineEntity = viewer.entities.add({
          polyline: {
            positions: [positions[positions.length - 2], position],
            width: 2,
            material: Cesium.Color.RED
          }
        });
        entities.push(lineEntity);
        // 计算两个点之间的距离
        const distance = Cesium.Cartesian3.distance(position, positions[positions.length - 2]);
        console.log(positions[positions.length - 2], '');
        // 计算线的中心位置
        const center = Cesium.Cartesian3.add(
          positions[positions.length - 2],
          position,
          new Cesium.Cartesian3()
        );
        const newcenter = Cesium.Cartesian3.divideByScalar(center, 2, center);
        // 创建 Label 标签来显示距离
        var labelEntity = viewer.entities.add({
          position: newcenter,
          label: {
            text: distance.toFixed(2) + 'm',
            font: '18px sans-serif',
            fillColor: Cesium.Color.WHITE,
            outlineColor: Cesium.Color.BLACK,
            outlineWidth: 2,
            style: Cesium.LabelStyle.FILL_AND_OUTLINE,
            pixelOffset: new Cesium.Cartesian2(0, -20)
          }
        });
        entities.push(labelEntity);
      }
    },
    createAfterPolyline() {
      if (positions.length > 1) {
        const lineEntity = viewer.entities.add({
          polyline: {
            positions: [positions[0], positions[positions.length - 1]],
            width: 2,
            material: Cesium.Color.RED
          }
        });
        entities.push(lineEntity);
        // 计算两个点之间的距离
        const distance = Cesium.Cartesian3.distance(positions[0], positions[positions.length - 1]);
        console.log('创建最后一条边-距离', distance);

        // 计算线的中心位置
        const center = Cesium.Cartesian3.add(
          positions[positions.length - 1],
          positions[0],
          new Cesium.Cartesian3()
        );
        console.log('创建最后一条边-距离计算线的中心位置', center);

        const newcenter = Cesium.Cartesian3.divideByScalar(center, 2, center);
        // 创建 Label 标签来显示距离
        var labelEntity = viewer.entities.add({
          position: newcenter,
          label: {
            text: distance.toFixed(2) + 'm',
            font: '18px sans-serif',
            fillColor: Cesium.Color.WHITE,
            outlineColor: Cesium.Color.BLACK,
            outlineWidth: 2,
            style: Cesium.LabelStyle.FILL_AND_OUTLINE,
            pixelOffset: new Cesium.Cartesian2(0, -20)
          }
        });
        entities.push(labelEntity);
      }
    },
    goEdit(item) {
      this.clearMarker();
      this.showStatus = this.$t('operation.Edit');
      this.editData = JSON.parse(JSON.stringify(item));
      this.showAdd = true;
    },
    // 删除
    async deleteItem(item) {
      const res = await Airspace.Delete({ id: item.id });
      try {
        if (res.code === 200) {
          this.$message.success(this.$t('flightApplication.删除成功'));
          this.getAirspaceList(this.search.num);
          this.showAllArea(viewer);
        } else {
          this.$message.error(res.message);
        }
      } catch (error) {
        console.log(error);
      }
    },
    async showOrHiddle(item) {
      const res = await Airspace.Edit({
        id: item.id,
        isEnable: !item.isEnable
      });
      try {
        if (res.code === 200) {
          this.$message.success(this.$t('flightApplication.操作成功'));
          this.getAirspaceList(1);
          this.showAllArea(viewer);
        } else {
          this.$message.error(res.message);
        }
      } catch (error) {
        console.log(error);
      }
    },
    // 获取空域列表
    async getAirspaceList(num) {
      this.search.num = num;
      const param = {
        page: num,
        pageSize: this.search.size,
        name: this.search.name ? this.search.name : undefined
      };
      await Airspace.airspaceList({ ...param }).then((resp) => {
        this.list = resp.data.records;
        this.totalPage = resp.data.total || resp.data.records.length;
      });
    },
    // 页码
    handleCurrentChange(val) {
      this.getAirspaceList(val);
    },
    handelSearch() {
      this.getAirspaceList(1);
    },
    closeAdd() {
      this.showAdd = false;
      this.editData = null;
      this.getAirspaceList(this.search.num);
    },
    clearMarker() {
      if (entities) {
        for (let i = 0; i < entities.length; i++) {
          const element = entities[i];
          viewer.entities.remove(element);
        }
        entities = [];
        positions = [];
        // 绘制的空域
        polygonEntity = null;
      }
    }
  },
  async created() {
    await this.getAirspaceList(1);
  },
  beforeDestroy() {
    // this.clearMarker()
  }
};
</script>

<style lang="scss" scope>
.flight-line-list {
  @import '~@/styles/list-common.scss';
  height: calc(100% + 38px);

  .search_box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: MicrosoftYaHei;
    font-size: 14px;
    color: #f8f8f8;
    letter-spacing: 0;
    text-align: center;
    font-weight: 400;
    padding: 0 8px;
    height: 48px;

    .btn_edit,
    .btn_search,
    .btn_create {
      box-sizing: border-box;
      width: 100px;
      height: 32px;
      background: #3a4252;
      border: 1px solid #3bc1e5;
      border-radius: 4px;
      line-height: 32px;

      .iconfont {
        font-size: 14px;
        margin-right: 5px;
      }
    }
  }

  .pagination {
    width: 100%;
    height: 30px;
    position: absolute;
    right: 6px;
    bottom: -34px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>
<style>
.el-input__icon {
  line-height: 32px !important;
}
.el-form-item__label {
  color: #ffffff;
}
</style>

<template>
  <div class="uav-control">
    <div class="search_box line">
      <el-input
        v-model="search.name"
        :placeholder="$t('flightApplication.inpueUavName')"
        prefix-icon="el-icon-search"
        @change="getUavList"
      />
    </div>
    <el-tree
      :data="data"
      node-key="id"
      default-expand-all
      :expand-on-click-node="false"
      :render-content="renderContent"
      :props="{ label: 'name', children: 'childs' }"
    />
    <!--  -->
    <div v-if="showTaskBox" class="taskBox">
      <div v-interact class="head">
        <div class="left">
          <span class="title">{{$t('flightApplication.libraryOfTasks')}}</span>
        </div>
        <div class="right">
          <span v-hover class="iconfont icon-guanbi" @click="cancel" />
        </div>
      </div>
      <div class="taskBox_body">
        <div class="flight-line-list">
          <div class="tb-box">
            <div class="tb-hd-box">
              <div class="tb-hd">{{$t('flightApplication.TaskName')}}</div>
              <div class="tb-hd">{{$t('flightApplication.ExecutionStatus')}}</div>
              <div class="tb-hd">{{$t('flightApplication.ExecutionTime')}}</div>
              <div class="tb-hd">{{$t('operation.Operation')}}</div>
            </div>
            <div class="tb-bd-box">
              <div v-for="item in list" :key="item.id" class="tb-tr">
                <div class="tb-task-item" @click="detailsItem(item)">
                  <div class="td">{{ item.taskName || $t('home.no')}}</div>
                  <div class="td" :style="{color: item.taskStatus == 2 ? '#FA8100 ' : '#C6CCD4',}">
                    {{ taskTypeName(item) || $t('home.no') }}
                  </div>
                  <div class="td">
                    {{ item.expectedtime || $t('home.no') }}
                  </div>
                  <div class="td">
                    <el-tooltip
                      v-hover
                      v-pms="['飞行任务', '详情']"
                      effect="dark"
                      :content="$t('operation.Detail')"
                      placement="top"
                    >
                      <img
                        src="~@/assets/image/icons/details.png"
                        style="color: #a16869; width: 26px; height: 26px"
                        @click.stop="detailsItem(item)"
                      />
                    </el-tooltip>
                    <el-tooltip
                      v-if="item.taskStatus === 0 && !item.executeButton"
                      v-hover
                      v-pms="['飞行任务', '一键任务']"
                      effect="dark"
                      :content="$t('flightApplication.一键任务')"
                      placement="top"
                    >
                      <img
                        src="~@/assets/image/icons/one_click_task.png"
                        style="color: #a16869; width: 26px; height: 26px"
                        @click.stop="oneClickTask(item)"
                      />
                    </el-tooltip>
                  </div>
                </div>

                <div class="details-body" :class="item.showTaskDetails ? 'open' : ''">
                  <el-tabs v-if="taskDetails" v-model="taskTypeId" @tab-click="handleClick">
                    <el-tab-pane :label="$t('flightApplication.TaskDetail')" name="0" />
                    <el-tab-pane :label="$t('flightApplication.TaskFlow')" name="1" />
                  </el-tabs>
                  <div v-if="taskTypeId === '0'" class="body">
                    <div class="item">
                      <div class="label">{{$t('flightApplication.TaskName')}}</div>
                      <div class="value">
                        {{ taskDetails && (taskDetails.taskName || $t('home.no')) }}
                      </div>
                    </div>
                    <div class="item">
                      <div class="label">{{$t('flightApplication.TaskType')}}</div>
                      <div class="value">
                        {{ taskDetails && (taskDetails.processName || $t('home.no')) }}
                      </div>
                    </div>
                    <div class="item">
                      <div class="label">{{$t('flightApplication.routeName')}}</div>
                      <div class="value">
                        {{ taskDetails && (taskDetails.routeName || $t('home.no')) }}
                      </div>
                    </div>
                    <div class="item">
                      <div class="label">{{$t('flightApplication.ExecutionStatus')}}</div>
                      <div class="value">{{ taskDetails && (taskTypeName(taskDetails) || $t('home.no'))}}</div>
                    </div>
                    <div class="item">
                      <div class="label">{{$t('flightApplication.ResponsibleAgency')}}</div>
                      <div class="value">{{ taskDetails && (taskDetails.orgName || $t('home.no'))}}</div>
                    </div>
                    <div class="item">
                      <div class="label">{{$t('home.uav')}}</div>
                      <div class="value">{{taskDetails && (taskDetails.deviceHardId || $t('home.no'))}}</div>
                    </div>
                    <div class="item">
                      <div class="label">{{$t('flightApplication.TaskDescription')}}</div>
                      <div class="value">
                        {{ taskDetails && (taskDetails.remark || $t('home.no')) }}
                      </div>
                    </div>
                    <div class="item">
                      <div class="label">{{$t('flightApplication.CreateTime')}}</div>
                      <div class="remark">
                        {{ taskDetails && (taskDetails.addtime || $t('home.no')) }}
                      </div>
                    </div>
                  </div>
                  <div
                    v-if="
                      taskTypeId === '1' &&
                      taskDetails.taskOperators &&
                      taskDetails.taskOperators.length
                    "
                    class="flow-chart"
                  >
                    <div class="example">
                      <div class="item">
                        <div class="example-line" />
                        <div class="example-label">{{$t('taskMangement.正常或通过')}}</div>
                      </div>
                      <div class="item">
                        <div class="example-line back" />
                        <div class="example-label">{{$t('taskMangement.异常或驳回')}}</div>
                      </div>
                    </div>
                    <div
                      v-for="(item, index) in taskDetails.taskOperators"
                      :key="item.id"
                      class="chart-body"
                    >
                      <div
                        v-if="
                          item.functionType === 0 || item.functionType === 2
                        "
                        class="pie"
                        :class="{
                          active:
                            item.isOperate === 0 || item.isOperate === 2
                              ? true
                              : false,
                        }"
                        @click="openTaskDetailsList(item)"
                      >
                        <div class="pie-body">
                          <div
                            class="label-functionType"
                            :title="typeFilter(item.functionType)"
                          >
                            {{ typeFilter(item.functionType) }}
                          </div>
                          <div class="label-orgName" :title="item.operatorName">
                            {{ item.operatorName }}
                          </div>
                        </div>
                      </div>

                      <div
                        v-if="item.functionType === 1"
                        class="diamond-pro"
                        @click="taskDetails = item"
                      >
                        <div class="diamond-body">
                          <div
                            class="label-functionType"
                            :title="typeFilter(item.functionType)"
                          >
                            {{ typeFilter(item.functionType) }}
                          </div>
                          <div class="label-orgName" :title="item.operatorName">
                            {{ item.operatorName }}
                          </div>
                        </div>
                        <div
                          class="diamond"
                          :class="{
                            active:
                              item.isOperate === 0 || item.isOperate === 2
                                ? true
                                : false,
                          }"
                        />
                      </div>
                      <!-- 驳回线条 -->
                      <!-- :style="{ height: `${90 + 43 + (90 * 1.414) / 2 - 90 / 2}px` }" -->
                      <div
                        v-if="item.functionType === 1 && item.isOperate === 3"
                        class="arrow-back"
                        :style="{ height: heightComputed(item, index) }"
                      >
                        <div class="back-top-box">
                          <div class="head" />
                          <div
                            class="line"
                            style="height: 3px"
                            :style="{
                              width: `calc(100% + (90px) * 1.414 / 2 - (90px) / 2 - 8px)`,
                            }"
                          />
                        </div>
                        <div class="back-middle-box line" />
                        <div class="back-bottom-box line" />
                      </div>

                      <div
                        v-if="index !== form.taskOperators.length - 1"
                        class="arrow"
                      >
                        <div class="arrow-line" />
                        <div class="arrow-head" />
                      </div>
                    </div>
                  </div>
                  <div
                    v-else-if="taskTypeId === '1' && !taskDetails.taskOperators"
                  >
                    <el-empty description="暂无流程" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="taskDetails" class="taskBox taskDetails">
      <div v-interact class="head">
        <div class="left">
          <span class="title">{{$t('taskMangement.任务流程详情')}}</span>
        </div>
        <div class="right">
          <span
            v-hover
            class="iconfont icon-guanbi"
            @click="closeTaskDetails"
          />
        </div>
      </div>
      <div class="body">
        <div class="item">
          <div class="label">{{$t('taskMangement.流程节点名称')}}</div>
          <div class="value">{{ taskDetails.labelName || $t('home.no') }}</div>
        </div>
        <div class="item">
          <div class="label">{{$t('taskMangement.节点功能')}}</div>
          <div class="value">
            {{ functionTypeList[taskDetails.functionType].label || $t('home.no') }}
          </div>
        </div>
        <div class="item">
          <div class="label">{{$t('taskMangement.指派操作员')}}</div>
          <div class="value">{{ taskDetails.operatorName || $t('home.no') }}</div>
        </div>
        <div class="item">
          <div class="label">{{$t('taskMangement.指派类型')}}</div>
          <div class="value">
            {{ operateClassList[taskDetails.operateClass].label || $t('home.no') }}
          </div>
        </div>
        <div class="item">
          <div class="label">{{$t('taskMangement.操作状态')}}</div>
          <div class="value">
            {{ isOperateList[taskDetails.isOperate].label || $t('home.no') }}
          </div>
        </div>
        <div class="item">
          <div class="label">{{$t('taskMangement.实际操作员')}}</div>
          <div class="value">
            {{ taskDetails.operateRealUsername || $t('home.no') }}
          </div>
        </div>
        <div class="item">
          <div class="label">{{$t('taskMangement.实际操作员机构')}}</div>
          <div class="value">
            {{ taskDetails.operateRealOgrName || $t('home.no') }}
          </div>
        </div>
        <div class="item">
          <div class="label">{{$t('taskMangement.操作时间')}}</div>
          <div class="remark">{{ taskDetails.operateTime || $t('home.no') }}</div>
        </div>
      </div>
    </div>

    <!--    <UavContent-->
    <!--      v-if="$uav"-->
    <!--      :task-details="taskDetails"-->
    <!--      :prop-open-operate="propOpenOperate"-->
    <!--      @getTaskList="(e) => getTaskListEnd(e)"-->
    <!--    />-->
  </div>
</template>

<script>
import { Control_API, Tg, flightTaskAPI } from "@/api";
import UavContent from "./uav-content/index.vue";
import { mapMutations } from "vuex";
const { VUE_APP_BASE_WS_URL } = process.env;
import { mapState } from "vuex";
let ws, viewer;
import { permissionIds } from "@/utils/permission";
import mqtt from "mqtt";
export default {
  components: { UavContent },
  inject: ["change_active"],
  data() {
    return {
      previouHardId: null,
      showData: false,
      data: [],
      openId: null,
      uavData: {},
      uavId: null,
      uavMap: {},
      user_info: JSON.parse(window.localStorage.getItem("user_info")),
      openData: {},
      uavDetails: null,
      timeHandle: null,
      search: {
        name: "",
      },
      showTaskDetails: false,

      typeOfRatify: [
        {
          id: 0,
          name: "无需审批",
        },
        {
          id: 1,
          name: "待审批",
        },
        {
          id: 2,
          name: "审批完成",
        },
        {
          id: 3,
          name: "已驳回",
        },
      ],
      taskDetails: null,
      mqtt: {
        client: null,
        decoder: null,
        encoder: new TextEncoder(),
      },
      subplatformInfo: JSON.parse(localStorage.getItem("subplatformInfo")),
      showDetailsId: null,
      propOpenOperate: false,
      taskTypeId: "0",
      is_arrow_back: false,
      showTaskBox: false,
      functionTypeList: [
        {
          value: 0,
          label: "发起",
        },
        {
          value: 1,
          label: "审批",
        },
        {
          value: 2,
          label: "执行",
        },
      ],
      operateClassList: [
        {
          label: "指定用户",
          value: 0,
        },
        {
          label: "指定机构",
          value: 1,
        },
      ],
      isOperateList: [
        {
          value: 0,
          label: "通过",
        },
        {
          value: 1,
          label: "未操作",
        },
        {
          value: 2,
          label: "通过",
        },
        {
          value: 3,
          label: "驳回",
        },
      ],
    };
  },
  computed: {
    ...mapState(["userInfo", "projectData"]),
    ...mapState("subplatform", ["subplatFormStatus"]),
    openTest() {
      return this.$store.state.uavApplications.openTest;
    },
    $uav() {
      return this.$store.state.uavApplications.uav;
    },
    // 是否显示视频
    showVideo: {
      get() {
        return this.$store.state.uavApplications.showVideo;
      },
      set(newVal) {
        this.$store.commit("uavApplications/setState", {
          key: "showVideo",
          value: newVal,
        });
      },
    },
  },
  watch: {
    subplatFormStatus(val) {
      this.subplatformInfo = JSON.parse(
        localStorage.getItem("subplatformInfo")
      );
    },
  },
  methods: {
    ...mapMutations(["SET_CESIUM_STATE", "SET_OPEN_LEFT_NAV_SILDER"]),
    /* onViewLibrary() {
      this.change_active(12);
    }, */
    openTaskDetailsList(item) {
      if (item.isOperate === 0) return;
      this.taskDetailsList = item;
    },
    closeTaskDetails() {
      this.taskDetails = null;
    },
    takeoverStatus(data) {
      if (data.currentOperator == 0) {
        return "未接管";
      } else if (data.currentOperator === this.userInfo.id) {
        return "正在接管中";
      }
      return (
        data.currentOperatorName +
        "正在接管中,账号为" +
        data.currentoperatorName
      );
    },
    heightComputed(item, index) {
      const arr = [];
      this.form.taskOperators.map((col, ind) => {
        if (ind > index) return;
        if (col.functionType === 0 || col.functionType === 2) {
          arr.push(90);
        } else if (index > 1 && ind > 1) {
          arr.push(90 * 1.414);
        } else {
          arr.push((90 * 1.414) / 2);
        }
      });
      const height = arr.reduce((old, now) => {
        return old + now;
      }, 0);
      return height - 90 / 2 + (30 + 8 + 5) * index + "px";
    },
    handleClick() {
      this.is_arrow_back = this.taskDetails.taskOperators.some(
        (i) => i.isOperate === 3
      );
    },
    typeFilter(typeNam) {
      if (typeNam === 0) {
        return "任务发起";
      } else if (typeNam === 1) {
        return "派出所审批";
      } else {
        return "任务执行";
      }
    },
    async getTaskList(item) {
      const res = await Control_API.getTaskList({
        target: 0,
        projectId: this.subplatformInfo.projectId,
        type: 0,
        deviceHardId: item.taskId ? item.hardId : undefined,
        // taskStatus: item.taskId ? 1 : 0,
        // approveStatus: 0,
        taskStatusList: item.taskId ? [1] : [0],
        approveStatusList: [0, 2],
      });
      if (res.code === 200) {
        // 有执行中的自动弹出详情框
        if (res.data.find((i) => i.taskStatus === 1)) {
          this.list = res.data;
          this.list.map((i) => {
            i.showTaskDetails = false;
          });
          this.detailsItem(res.data[0]);
        } else {
          this.list = res.data;
          this.list.map((i) => {
            i.showTaskDetails = false;
          });
          this.showTaskDetails = false;
        }
      }
    },

    async oneClickTask(item) {
      if (!this.$uav.currentOperator) {
        const messageValue = {
          title: this.$t('home.SafetyConfirmation'),
          message: `请确认是否接管${this.$uav.name}且进行一键任务操作？`,
          confirmButtonText: this.$t('operation.Confirm'),
          cancelButtonText: this.$t('operation.Cancel'),
          fun: async () => {
            try {
              const res = await Tg.setUavControlOn({
                force: false,
                id: this.$uav.id,
                uid: this.userInfo.id,
              });
              if (res.code === 200) {
                this.$message.success(`${this.$t('home.takeOverSuccess')}${this.$uav.name}`);
                await this.getUavList();
              }
              this.$store.dispatch("uavApplications/takeOff", {
                callback: async (isOk) => {
                  if (isOk) {
                    this.$message.success("发出一键任务指令成功");
                    this.propOpenOperate = true;
                    const res = await flightTaskAPI.editFlight({
                      ...item,
                      changeSchedul: false,
                      deviceHardId: this.$uav.hardId,
                      taskStatus: 1,
                    });
                    if (res.code == 200) {
                      this.$message({
                        showClose: true,
                        type: "success",
                        message: this.$t('flightApplication.操作成功'),
                        duration: 1000,
                      });
                      const newItem = JSON.parse(JSON.stringify(item));
                      newItem.taskId = item.id;
                      newItem.hardId = this.$uav.hardId;
                      // await this.getTaskList(newItem);
                      // if (this.showTaskDetails) this.showTaskDetails = false;
                      this.$store.commit("uavApplications/setState", {
                        key: "TaskList",
                        value: newItem,
                      });
                    } else {
                      this.$message({
                        showClose: true,
                        type: "error",
                        message: res.msg,
                        duration: 1000,
                      });
                    }
                    // 控制无人机数据界面解锁
                    this.fly_control_unlock("fly_control_unlock");
                  } else {
                    return this.$message.warning("发出一键任务指令失败");
                  }
                },
              });
            } catch (e) {
              this.$message.warning("解析航线失败");
              console.log("解析航线失败", e);
            }
          },
        };
        this.messageBox(messageValue);
      } else {
        this.$confirm("请确认是否进行一键任务操作?", this.$t('home.SafetyConfirmation'), {
          cancelButtonText: this.$t('operation.Cancel'),
          confirmButtonText: this.$t('operation.Confirm'),
          customClass: "uav_controlPane",
          showClose: false,
        }).then(async () => {
          this.$store.commit("uavApplications/setState", {
            key: "airlineData",
            value: item,
          });
          try {
            this.$store.dispatch("uavApplications/takeOff", {
              callback: async (isOk) => {
                if (isOk) {
                  this.$message.success("发出一键任务指令成功");
                  const res = await flightTaskAPI.editFlight({
                    ...item,
                    changeSchedul: false,
                    deviceHardId: this.$uav.hardId,
                    taskStatus: 1,
                  });
                  if (res.code == 200) {
                    this.$message({
                      showClose: true,
                      type: "success",
                      message: this.$t('flightApplication.操作成功'),
                      duration: 1000,
                    });
                    const newItem = JSON.parse(JSON.stringify(item));
                    newItem.taskId = item.id;
                    newItem.hardId = this.$uav.hardId;
                    // 打开任务列表
                    // await this.getTaskList(newItem);
                    // if (this.showTaskDetails) this.showTaskDetails = false;
                    // 打开任务列表
                    this.$store.commit("uavApplications/setState", {
                      key: "TaskList",
                      value: newItem,
                    });
                  } else {
                    this.$message({
                      showClose: true,
                      type: "error",
                      message: res.msg,
                      duration: 1000,
                    });
                  }
                  this.fly_control_unlock("fly_control_unlock");
                } else {
                  return this.$message.warning("发出一键任务指令失败");
                }
              },
            });
          } catch (e) {
            this.$message.warning("解析航线失败");
            console.log("解析航线失败", e);
          }
        });
      }
    },
    fly_control_unlock(key) {
      const seed = Date.now();
      this.$store.commit("uavApplications/setState", {
        key,
        value: seed,
      });
    },

    onPlay(data) {
      console.log(data, "点击视频按钮！！！");
      // 清除鹰巢显示
      this.$store.commit("uavApplicationsEagleNest/setState", {
        key: "currentEagle",
        value: null,
      });
      // 未选中或者切换飞机时需要先选中飞机
      if (!this.$uav || this.$uav.hardId !== data.hardId) {
        this.onOperate(data);
      }
      // 已选中或者切换飞机情况下,开关视频
      // if (this.$uav) {
      //   this.showVideo = true;
      //   // this.showVideo = !this.showVideo;
      // } else {
      //   this.showVideo = true;
      // }
      if (data.hardId == this.previouHardId) {
        this.showVideo = !this.showVideo;
      } else {
        this.showVideo = true;
      }
      this.previouHardId = data.hardId;
      this.openVideoData = this.openData;
    },
    async onOperate(data) {
      if (!this.openTest && !data.online) {
        return this.$message.warning(this.$t('flightApplication.无人机离线中'));
      }
      // 打开飞行模式弹出框
      this.$store.commit("uavApplications/setState", {
        key: "FlyModelFlag",
        value: true,
      });
      this.$store.commit("SET_OPEN_LEFT_NAV_SILDER", false);
      // this.$store.commit("CHANGE_ACTYIVE", 0);
      let actionType = -1; // 动作类型, 0: 切换飞机 1: 取消选择
      if (this.$uav) {
        // 已选择飞机, 判断当前操作的是否为同一台,是的话取消选择, 不是则切换飞机
        if (this.$uav.hardId === data.hardId) {
          actionType = 1;
        } else {
          actionType = 0;
        }
      } else {
        // 未选择飞机, 直接选中飞机
        actionType = 0;
        data._video = !data._video;
      }

      // 两种情况进入, 1.从未选到选择, 2.切换飞机
      if (actionType === 0) {
        if (this.subplatFormStatus && this.subplatformInfo) {
          try {
            // await this.getTaskList(data);
            // this.showTaskBox = true;
            // 打开任务列表
            this.$store.commit("uavApplications/setState", {
              key: "TaskList",
              value: data,
            });
            data._video = !data._video;
          } catch (error) {
            console.log(error);
          }
        }

        // 控制飞机
        this.openData = data;
        this.showData = true;
        this.$bus.$emit("clickUavTab");
        this.$bus.$emit("changeUavName", data.name);

        this.$bus.$emit("changeFlightInfo", data);
        this.$store.commit("SET_CESIUM_STATE", true);
        try {
          // 切换飞机前先销毁前一个飞机的数据
          if (this.$uav && this.$uav.hardId !== data.hardId) {
            const hardId = this.$uav.hardId;
            // 判断上一台飞机,属于飞控中心还是地面站, 执行对应的取消订阅操作
            if (this.$uav.network === 1) {
              this.$store.dispatch("MMCMQTT/unsubscribe", {
                topic: "PX4/RECEIVE/" + hardId,
                callback() {
                  console.log("mqtt取消订阅成功", "PX4/RECEIVE/" + hardId);
                },
              });
            } else {
              this.$store.dispatch("MMCGroundStation/unsubscribe", hardId);
              console.log("地面站取消订阅成功", hardId);
            }
          }
        } catch (error) {
          console.log(error);
        }
        this.$store.dispatch("uavApplications/destroy");

        this.$store.commit("uavApplications/setState", {
          key: "uav",
          value: data,
        });
      try {
        if (data.network == 1) {
          // 飞控中心链路
          this.$store.dispatch("MMCMQTT/subscribe", {
            topic: "PX4/RECEIVE/" + data.hardId,
            callback() {
              console.log("mqtt主题订阅成功", "PX4/RECEIVE/" + data.hardId);
            },
          });
        } else {
          // 地面站链路
          this.$store.dispatch("MMCGroundStation/subscribe", data.hardId);
          console.log("地面站主题订阅成功", data.hardId);
        }

      } catch (error) {
        console.log(error);
      }

        // 清除鹰巢显示
        this.$store.commit("uavApplicationsEagleNest/setState", {
          key: "currentEagle",
          value: null,
        });
      } else if (actionType === 1) {
        if (this.showTaskBox || this.showTaskDetails) {
          this.showTaskBox = false;
          this.showTaskDetails = false;
        }
        // 取消选择
        this.showData = false;
        this.$bus.$emit("clickUavTab");
        this.$bus.$emit("changeUavName", null);
        this.$bus.$emit("changeFlightInfo", null);
        this.openData = {};
        this.$store.dispatch("uavApplications/destroy");
        if (data.network == 1) {
          // 飞控中心链路
          this.$store.dispatch("MMCMQTT/unsubscribe", {
            topic: "PX4/RECEIVE/" + data.hardId,
            callback() {
              console.log("mqtt取消订阅成功", "PX4/RECEIVE/" + data.hardId);
            },
          });
        } else {
          // 地面站链路
          this.$store.dispatch("MMCGroundStation/unsubscribe", data.hardId);
        }
      }
      console.log("video", "videovideovideo");
      // 已选中或者切换飞机情况下,开关视频
      if (this.$uav) {
        this.showVideo = true;
      } else {
        this.showVideo = false;
      }
      // 测试mqtt链接
      // this.$store.commit("uavApplications/setState", {
      //   key: "uav",
      //   value: data,
      // });
      // this.showVideo = true
      // this.mqttInit(data)
    },
    mqttInit(data) {
      console.log(data);
      const { VUE_APP_MQTT_URL } = process.env;
      const url = VUE_APP_MQTT_URL;
      // let url = 'wss://jianzai.mmcuav.cn/mqtt'
      console.log("测试开始连接", VUE_APP_MQTT_URL);
      this.mqtt.client = mqtt.connect(url, {
        // protocol: "mqtt",
        clientId: Date.now(),
      });
      this.mqtt.client.on("connect", () => {
        console.log("mqtt is connected");
      });
      this.mqtt.client.on("reconnect", function (reconnect) {
        console.log(reconnect, "reconnect");
      });
      const key = this.PoliceKeyCode;
      const topic = `PX4/RECEIVE/${data.hardId}`;
      console.log(topic, "链接主题");
      this.subscribe(`PX4/RECEIVE/${data.hardId}`, () => {
        console.log(`订阅无人机数据：topic:${topic}`);
      });
      const message = {
        data: {
          messageID: 1017,
          data: {
            taskID: "",
            videoID: 1,
            name: "z30Pro",
            dbID: "911",
            status: true,
          },
        },
        type: 528,
      };
      this.mqtt.client.publish(
        `PX4/OBTAIN/${data.hardId}`,
        new TextEncoder().encode(JSON.stringify(message)),
        { qos: 0 },
        (e) => {
          console.log(e, message, "返回的数据");
        }
      );
      // let music_list = [];
      const music_list = {};
      this.mqtt.client.on("message", (topic, payload, packet) => {
        const data = JSON.parse(packet.payload.toString());
        music_list[data.type] = data.data;
        console.log(data, music_list, "musicdata");
      });
    },
    subscribe(topic, callback) {
      if (!topic) {
        return topic;
      }
      const { client } = this.mqtt;
      if (client) {
        console.log("订阅数据a", topic);
        client.subscribe(topic, callback);
      }
    },
    messageBox({ title, message, confirmButtonText, cancelButtonText, fun }) {
      if (!title || !message) return console.log("请输入提示标题以及提示内容");
      const value = {
        title,
        message,
        center: true,
      };
      if (confirmButtonText) {
        value.confirmButtonText = confirmButtonText;
      }
      if (cancelButtonText) {
        value.cancelButtonText = this.$t('operation.Cancel');
        value.showCancelButton = true;
      }
      this.$msgbox({
        ...value,
        beforeClose: (action, instance, done) => {
          done();
        },
      }).then((action) => {
        fun && fun();
      });
    },
    takeOverTheDrone(data) {
      // 553-（正式服）【飞行应用-无人机列表】不在线的无人机无法取消监管

      let messageValue;
      if (data.currentOperator === this.userInfo.id) {
        // 是否被本账号接管
        messageValue = {
          title: this.$t('home.exitTakeOver'),
          message: `${this.$t('home.isExitTakeOver')}${data.name}`,
          confirmButtonText: this.$t('operation.Confirm'),
          cancelButtonText: this.$t('operation.Cancel'),
          fun: async () => {
            const res = await Tg.setUavControlOff(data.id);
            if (res.code === 200) {
              this.$message.success(`${this.$t('home.HaveBeenTakenOver')}${data.name}`);
              await this.getUavList();
            }
          },
        };

        // this.messageBox("退出接管", `是否退出接管${data.name}`, async () => {
        //   const res = await Tg.setUavControlOff(data.id)
        //   if (res.code === 200) {
        //     this.$message.success(`${this.$t('home.HaveBeenTakenOver')}${data.name}`)
        //     await this.getUavList()
        //   }
        // })
      } else if (data.currentOperator) {
        // 不是本账号接管的
        messageValue = {
          title: this.$t('operation.Hint'),
          message: `当前选择的无人机正在被其他用户接管，请稍后操作或联系接管人？`,
          confirmButtonText: "好的,知道了",
        };
        // this.messageBox(this.$t('operation.Hint'), `当前选择的无人机正在被其他用户接管，请稍后操作或联系接管人？`)
      } else if (!data.currentOperator) {
        if (!data.online) {
          return this.$message.warning(this.$t('flightApplication.无人机离线中'));
        }
        // 未被接管
        messageValue = {
          title: this.$t('home.takeoverUAV'),
          message: `${this.$t('home.takeOverOrNot')}${data.name}`,
          confirmButtonText: this.$t('operation.Confirm'),
          cancelButtonText: this.$t('operation.Cancel'),
          fun: async () => {
            const res = await Tg.setUavControlOn({
              force: false,
              id: data.id,
              uid: this.userInfo.id,
            });
            if (res.code === 200) {
              this.$message.success(`${this.$t('home.takeOverSuccess')}${data.name}`);
              await this.getUavList();
            }
          },
        };
        // this.messageBox("接管无人机", `是否接管${data.name}`, async () => {
        //   const res = await Tg.setUavControlOn({
        //     force: false,
        //     id: data.id,
        //     uid: this.userInfo.id
        //   })
        //   if (res.code === 200) {
        //     this.$message.success(`${this.$t('home.takeOverSuccess')}${data.name}`)
        //     await this.getUavList()
        //   }
        // })
      } else {
        console.log(data, "错误");
      }
      this.messageBox(messageValue);
    },
    permissionFn(pid) {
      const ids = permissionIds();
      if (ids) {
        if (ids.some((id) => id === pid)) {
          return "";
        } else {
          return "dn";
        }
      } else {
        return "dn";
      }
    },
    renderContent(h, { node, data, store }) {
      // console.log(node.label, node, data, store);
      if (
        !(data.children && data.children.length) &&
        data.type &&
        data.type === "uavs"
      ) {
        return (
          <span
            class="custom-tree-node"
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "300px",
              alignItems: "center",
              color: node.data.online ? "#00FF00" : "#808080",
            }}
          >
            <span class="uav-title">
              <div
                class="uav-title__icon "
                title={"型号:" + data.modelName}
              ></div>
              <span class="uav-title__text" title={node.label}>
                {node.label}
              </span>
            </span>
            <span class="ctrl-list">
              <div
                class={`ctrl-icon icon--control ${
                  this.openData && this.openData.hardId === data.hardId
                    ? "active"
                    : ""
                }`}
                on-click={() => this.onOperate(data)}
                title="控制"
              ></div>
              <div
                class={`ctrl-icon ${this.permissionFn(461)} ${
                  data.currentOperator &&
                  data.currentOperator === this.user_info.id
                    ? "icon--take-over-control-on"
                    : "icon--take-over-control"
                }`}
                on-click={() => this.takeOverTheDrone(data)}
                title={this.takeoverStatus(data)}
              ></div>
              {/* <div class={`ctrl-icon icon--lib ${(node.data.online || this.openTest) ? '' : 'disable'}`}
                on-click={() => this.onViewLibrary(node, data)}>
              </div> */}
              <div
                class={`ctrl-icon icon--video ${this.permissionFn(459)} ${
                  this.showVideo &&
                  this.openVideoData &&
                  this.openVideoData.hardId === data.hardId
                    ? "active"
                    : ""
                }`}
                disabled={!node.data.online}
                on-click={() => this.onPlay(data)}
                title="视频"
              ></div>
            </span>
            <span
              class="take-over"
              title={
                node.data.currentOperatorName
                  ? `${node.data.currentOperatorName}正在接管中,账号为${
                      node.data.username || ""
                    }`
                  : ""
              }
            >
              {node.data.currentOperatorName
                ? `${node.data.currentOperatorName}正在接管中,账号为${
                    node.data.username || ""
                  }`
                : ""}
            </span>
          </span>
        );
      } else {
        return (
          <span class="custom-tree-node">
            {/* <span>{`${node.label} ${node.data.online}/${node.data.online + node.data.offline} `}</span> */}
            <span>{`${node.label}`}</span>
            <span style="color: #409EFF;padding-left:4px;">{`${node.data.online}`}</span>
            <span>{`/${node.data.online + node.data.offline} `}</span>
          </span>
        );
      }
    },
    findList(list, uavListName, childrenName) {
      try {
        list.forEach((element) => {
          if (
            element[uavListName] &&
            element[uavListName].length &&
            element.type !== uavListName
          ) {
            element[uavListName].forEach((e) => (e.type = uavListName));
            element[childrenName] = [
              ...element[uavListName],
              ...element[childrenName],
            ];
          }
          if (this.$uav && element[uavListName]) {
            // 实时更新无人机数据，currentOperator 接管人参数
            const newVal = element[uavListName].find(
              (e) => e.id === this.$uav.id
            );
            if (
              newVal &&
              newVal.currentOperator !== this.$uav.currentOperator
            ) {
              this.$store.commit("uavApplications/setState", {
                key: "uav",
                value: {
                  ...this.$uav,
                  currentOperator: newVal.currentOperator,
                },
              });
            }
          }
          if (element[childrenName] && element[childrenName].length) {
            this.findList(element[childrenName], "uavs", "childs");
          }
        });
      } catch (e) {
        console.log(e);
      }
    },
    async getUavList() {
      const res = await Control_API.getUavTree({
        name: this.search.name,
      });
      if (res.code === 200) {
        this.data = res.data;
        this.findList(this.data, "uavs", "childs");
      }
    },
  },
  async created() {
    this.getUavList();
    this.timeHandle = setInterval(async () => {
      this.getUavList();
    }, 25000);
  },
  mounted() {
    viewer = window.viewer;
  },
  beforeDestroy() {
    clearInterval(this.timeHandle);
    this.$store.dispatch("uavApplications/destroy");
    // if (data.network == 1) {
    //   //飞控中心链路
    //   this.$store.dispatch("MMCMQTT/end");
    // } else {
    //   //地面站链路
    //   this.$store.dispatch("MMCGroundStation/end");
    // }
  },
};
</script>

<style lang="scss" scoped>
@import "~@/styles/list-common.scss";
.uav-control {
  height: 100%;
  .search_box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: MicrosoftYaHei;
    font-size: 14px;
    color: #f8f8f8;
    letter-spacing: 0;
    text-align: center;
    font-weight: 400;
    padding: 0 8px;
    height: 48px;

    .btn_edit,
    .btn_search,
    .btn_create {
      box-sizing: border-box;
      width: 100px;
      height: 32px;
      background: #3a4252;
      border: 1px solid #3bc1e5;
      border-radius: 4px;
      line-height: 32px;

      .iconfont {
        font-size: 14px;
        margin-right: 5px;
      }
    }

    &::v-deep {
      .el-input {
        margin: 0px 8px;
        border: 1px solid #233a58;
        border-radius: 10px;

        .el-input__icon {
          line-height: 32px;
          color: #1eb9fa;
        }
      }
    }
  }

  ::v-deep {
    .el-tree {
      box-sizing: content-box;
      height: calc(100% - 48px);
      overflow: auto;
      margin-right: 10px;
      color: #c6ccd4;
      font-size: 12px;
      background: none !important;

      .el-tree-node:after {
        border-top: none;
      }

      .el-tree-node {
        position: relative;
        padding-left: 16px;
        box-sizing: border-box;
        background: none;
        /*设置横向滚动条*/
        min-width: 100% !important;
        display: block !important;
        float: left;

        .el-tree-node__content {
          background: none;
          border: 1px solid transparent;
          padding-left: 0 !important;
          padding-top: 5px;

          &:hover .custom-tree-node {
            border: 1px solid #c6ccd4;
            background: none;
          }
        }
      }

      .is-current {
        .el-tree-node {
          .el-tree-node__content {
            &.custom-tree-node {
              border: 1px solid transparent;
              padding-left: 0 !important;
            }

            &:hover .custom-tree-node {
              border: 1px solid #c6ccd4;
              background: none;
            }
          }
        }

        .el-tree-node__content .custom-tree-node {
          border: 1px solid #c6ccd4;
        }
      }

      //节点有间隙，隐藏掉展开按钮就好了,如果觉得空隙没事可以删掉
      .el-tree-node__expand-icon.is-leaf {
        display: none;
      }

      .el-tree-node__children {
        padding-left: 16px;
      }

      .el-tree-node :last-child:before {
        height: 38px;
      }

      .el-tree-node:before {
        border-left: none;
      }

      .el-tree-node:after {
        border-top: none;
      }

      .el-tree-node:before {
        content: "";
        left: -4px;
        position: absolute;
        right: auto;
        border-width: 1px;
      }

      .el-tree-node:after {
        content: "";
        left: -4px;
        position: absolute;
        right: auto;
        border-width: 1px;
      }

      .el-tree-node:before {
        border-left: 1px solid #2c343c;
        bottom: 0px;
        height: 100%;
        top: -26px;
        width: 1px;
      }

      .el-tree-node:after {
        border-top: 1px solid #2c343c;
        height: 20px;
        top: 12px;
        width: 18px;
      }

      .el-tree-node__content > .el-tree-node__expand-icon {
        padding: 0;
      }

      .el-tree-node__expand-icon.expanded {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
      }

      .el-icon-caret-right:before {
        content: "\e723";
        font-size: 16px;
      }

      .el-tree-node__expand-icon.expanded.el-icon-caret-right:before {
        content: "\e722";
        font-size: 16px;
      }
    }

    .uav-title {
      width: 200px;
      display: flex;
      gap: 2px;
      align-items: center;
      overflow: hidden;

      .uav-title__icon {
        width: 20px;
        height: 20px;
        background-image: url("./assets/uav.png");
        background-size: 100% 100%;
        flex-shrink: 0;
      }

      .uav-title__text {
        width: 200px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .modelTag {
      color: #000;
      height: 14px;
      background-color: rgba($color: #fff, $alpha: 0.7);
      font-size: 12px;
      padding: 4px;
      border-radius: 5px;
    }

    .ctrl-list {
      display: flex;
      gap: 2px;
      .ctrl-icon {
        width: 24px;
        height: 24px;

        &.icon--control {
          background-image: url("./assets/control1.svg");
          background-size: 100% 100%;
          opacity: 0.7;
          &.active {
            opacity: 1;
            background-image: url("./assets/control2.svg");
          }
        }

        &.icon--take-over-control {
          background-image: url("./assets/take-over-control.png");
          background-size: 100% 100%;
          opacity: 0.5;
          &.active {
            opacity: 1;
          }
        }

        &.icon--take-over-control-on {
          background-image: url("./assets/take-over-control-on.png");
          background-size: 100% 100%;
          opacity: 0.7;
          &.active {
            opacity: 1;
          }
        }

        &.icon--lib {
          background-image: url("./assets/lib.png");
          background-size: 100% 100%;
          opacity: 0.7;
          &.active {
            opacity: 1;
          }
        }

        &.icon--video {
          background-image: url("./assets/video1.svg");
          background-size: 100% 100%;
          opacity: 0.7;
          &.active {
            opacity: 1;
            background-image: url("./assets/video.svg");
          }
        }
      }
    }
    .take-over {
      color: rgb(245, 154, 35);
      margin-left: 3px;
      width: 120px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
  .taskBox {
    position: fixed;
    left: 451px;
    top: 54px;
    width: 352px;
    height: 448px;
    background: rgba(19, 21, 24, 0.7);
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5),
      inset 0px 16px 48px 0px rgba(93, 173, 255, 0.15),
      inset 0px 4px 48px 0px rgba(53, 76, 147, 0.5),
      inset 0px 1px 4px 0px #2e63dc;
    border-radius: 8px;
    border: 1px solid;
    border-image: linear-gradient(
        180deg,
        rgba(52, 223, 255, 1),
        rgba(24, 113, 214, 0)
      )
      1 1;
    backdrop-filter: blur(4px);
    .head {
      box-sizing: border-box;
      width: 352px;
      height: 32px;
      box-shadow: 0px 1px 0px 0px rgba(255, 255, 255, 0.05);
      border-radius: 4px 4px 0px 0px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 10px;
      .left {
        font-size: 14px;
        font-family: MicrosoftYaHei;
        color: #ebf5ff;
      }
      .right {
        color: #ebf5ff;
      }
    }
    .taskBox_body {
      height: calc(100% - 38px);
      .flight-line-list {
        height: 100%;
        .tb-box {
          height: 100%;
          .tb-bd-box {
            ::v-deep {
              .el-tabs {
                .el-tabs__nav-scroll {
                  display: flex;
                  justify-content: center;
                }
                .el-tabs__active-bar {
                  background-color: #a4ff64;
                }
                .el-tabs__nav-wrap::after {
                  background-color: transparent;
                }
                .el-tabs__item {
                  color: #fff;
                  &:hover {
                    color: #a4ff64;
                  }
                  &.is-active {
                    color: #a4ff64;
                  }
                }
              }
            }
            .tb-tr {
              display: flex;
              flex-direction: column;
              &:hover {
                border: 1px solid transparent;
              }
              .tb-task-item {
                display: flex;
                border: 1px solid transparent;
                &:hover {
                  border: 1px solid #ccc;
                }
              }
              .details-body {
                transition: all 1s;
                background: #2c313b;
                border-radius: 4px 4px 0px 0px;
                overflow: auto;
                height: 0px;
                &.open {
                  height: 430px;
                  padding: 10px 8px;
                }
                .body {
                  height: calc(100% - 92px);
                  .item {
                    margin-top: 8px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    .label {
                      width: 48px;
                      font-size: 12px;
                      font-family: MicrosoftYaHei;
                      color: #b3bbc5;
                      margin-right: 16px;
                    }
                    .value {
                      box-sizing: border-box;
                      width: 232px;
                      height: 32px;
                      background: #16181b;
                      border-radius: 8px;
                      border: 1px solid #233a58;
                      font-size: 14px;
                      color: #d8dde8;
                      padding: 6px 12px;
                      font-family: PingFangSC-Regular, PingFang SC;
                    }
                    .remark {
                      box-sizing: border-box;
                      width: 232px;
                      height: 67px;
                      background: #16181b;
                      border-radius: 8px;
                      font-size: 14px;
                      border: 1px solid #233a58;
                      color: #d8dde8;
                      padding: 6px 12px;
                    }
                  }
                }
                .flow-chart {
                  max-height: 329px;
                  max-width: 435px;
                  overflow: auto;
                  position: relative;
                  padding-bottom: 10px;
                  .example {
                    position: absolute;
                    left: 20px;
                    top: 0;
                    width: 110px;
                    .item {
                      display: flex;
                      justify-content: space-between;
                      align-items: center;
                      .example-line {
                        width: 20px;
                        height: 3px;
                        background-color: #52c2e9;
                        &.back {
                          background-color: #c63d3d;
                        }
                      }
                      .example-label {
                        font-size: 14px;
                        color: #fff;
                      }
                    }
                  }
                  .chart-body {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    .pie {
                      width: 88px;
                      height: 88px;
                      border-radius: 50%;
                      background-color: #29260e;
                      border: 1px solid #8d7944;
                      display: flex;
                      flex-direction: column;
                      justify-content: center;
                      align-items: center;
                      .pie-body {
                        text-align: center;
                      }
                    }
                    .diamond-pro {
                      width: calc(90px * 1.414);
                      height: calc(90px * 1.414);
                      display: flex;
                      justify-content: center;
                      align-items: center;
                      position: relative;
                      .diamond-body {
                        position: absolute;
                        text-align: center;
                        z-index: 1;
                      }
                      .diamond {
                        width: 90px;
                        height: 90px;
                        transform: rotate(45deg);
                        background-color: #29260e;
                        border: 1px solid #8d7944;
                      }
                    }
                    .label-functionType {
                      font-size: 14px;
                      font-family: PingFangSC-Medium, PingFang SC;
                      font-weight: 500;
                      color: #ffffff;
                      line-height: 20px;
                    }
                    .label-orgName {
                      font-size: 12px;
                      font-family: PingFangSC-Regular, PingFang SC;
                      font-weight: 400;
                      color: #ffffff;
                      line-height: 17px;
                    }
                    .arrow {
                      width: 3px;
                      .arrow-line {
                        height: 30px;
                        background-color: #52c2e9;
                      }
                      .arrow-head {
                        border: 5px solid transparent;
                        border-top: 8px solid #52c2e9;
                        transform: translateX(-3px);
                      }
                    }
                    .arrow-back {
                      position: absolute;
                      left: calc(50% + 90px * 1.414 / 2);
                      top: calc(90px / 2);
                      width: 25px;
                      .back-line {
                        background-color: #c63d3d;
                      }
                      .back-top-box {
                        .head {
                          position: absolute;
                          left: calc((-90px) * 1.414 / 2 - (-90px) / 2 - 5px);
                          top: 0;
                          border: 5px solid transparent;
                          border-right: 8px solid #c63d3d;
                          transform: translateY(-3px);
                        }
                        .line {
                          position: absolute;
                          right: 0;
                        }
                      }
                      .back-middle-box {
                        position: absolute;
                        top: 0;
                        right: 0;
                        height: 100%;
                        width: 3px;
                      }
                      .back-bottom-box {
                        position: absolute;
                        bottom: 0;
                        width: 100%;
                        height: 3px;
                      }
                    }
                    .active {
                      background-color: #0e290e !important;
                      border: 1px solid #448d5f !important;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .taskDetails {
    position: absolute;
    height: unset;
    right: -92%;
    top: 0%;
    z-index: 1;
    background: rgba(19, 21, 24, 0.7);
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5),
      inset 0px 16px 48px 0px rgba(93, 173, 255, 0.15),
      inset 0px 4px 48px 0px rgba(53, 76, 147, 0.5),
      inset 0px 1px 4px 0px #2e63dc;
    border-radius: 8px;
    border: 1px solid;
    border-image: linear-gradient(
        180deg,
        rgba(52, 223, 255, 1),
        rgba(24, 113, 214, 0)
      )
      1 1;
    backdrop-filter: blur(4px);
    .head {
      box-sizing: border-box;
      width: 352px;
      height: 32px;
      box-shadow: 0px 1px 0px 0px rgba(255, 255, 255, 0.05);
      border-radius: 4px 4px 0px 0px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 10px;
      .left {
        font-size: 14px;
        font-family: MicrosoftYaHei;
        color: #ebf5ff;
      }
      .right {
        color: #ebf5ff;
      }
    }
    .body {
      margin: 10px 8px;
      background: #2c313b;
      border-radius: 4px 4px 0px 0px;
      overflow: auto;
      height: auto;
      .item {
        margin-top: 8px;
        padding: 0 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .label {
          font-size: 12px;
          font-family: MicrosoftYaHei;
          color: #b3bbc5;
          margin-right: 16px;
        }
        .value {
          box-sizing: border-box;
          width: 220px;
          height: 32px;
          background: #16181b;
          border-radius: 8px;
          border: 1px solid #233a58;
          font-size: 14px;
          color: #d8dde8;
          padding: 6px 12px;
          font-family: PingFangSC-Regular, PingFang SC;
        }
        .remark {
          box-sizing: border-box;
          width: 220px;
          height: 67px;
          background: #16181b;
          border-radius: 8px;
          font-size: 14px;
          border: 1px solid #233a58;
          color: #d8dde8;
          padding: 6px 12px;
        }
      }
    }
  }
}
</style>

<template>
  <div class="videoListbox">
    <el-select v-model="name" class="fr w150" :placeholder="$t('home.allocationSetting')">
      <el-option
        v-for="(item, index) in list"
        :key="index"
        :label="item.name"
        :value="item.name"
      />
    </el-select>  

    <div class="content">
      <div
        v-for="(item, index) in videoList"
        :key="index"
        class="pb20 defaultBorder"
        :class="{
          videoListboxItem: name == $t('home.fourSplitScreen'),
          videoListboxItem_J: name == $t('home.nineSplitScreen'),
          videoListboxItemActive: index === itemIndex,
        }"
        @click="changeVideoModel(index)"
      >
        <div @click.stop="stopPropagationFn">
          <MMCPlayer
              ref="MMCPlayer"
              class="MMCPlayerStyle"
              :title="item.name"
              :stream-options="item.streamUrlMessage"
              :size="2"
            />
        </div>
        <div class="cf bottom7 left60 osdInfo pa" style="z-index: 11">
          <div>
            <el-tooltip
              class=" w20"
              :content="$t('home.uavInfomation')"
              placement="top"
            >
              <div
                class="cp"
                @click="changeOSD(item)"
              >
              <img src="@/assets/image/uav.png"></img>
              </div>
            </el-tooltip>
          </div>
        </div>
        <OSD   :osdStyle="{height:'100%'}" :isInfoShow='item.isInfoShow'  />
      </div>
    </div>  
  </div>
</template>
<script>
import linkFusion from "@/views/uavApplications/mixins/linkFusion";
import { videoWell } from "@/api";

import { coverStreamUrl } from "@/utils";
import OSD from "../osd/index.vue";

export default {
  components:{
  OSD
},
  directives: {
    'click-dblclick': {
    bind(el, binding, vnode) {
      let clicks = 0, timer = null;
      el.addEventListener('click', (event) => {
        clicks++;
        if (clicks === 1) {
          timer = setTimeout(() => {
            // 单击事件处理
            binding.value.click(event);
            clicks = 0;
          }, 300);
        } else if (clicks === 2) {
          // 双击事件处理
          clearTimeout(timer);
          binding.value.dblclick(binding.value.value);
          clicks = 0;
        }
      });
    }
  }
  },
  mixins: [linkFusion],
  data() {
    return {
      isLogin: localStorage.getItem("tmj_token"),
      data: null,
      itemIndex: 0,
      streamOptions: [
        {
          label: "WebRtc",
          brand: "webrtc",
          url: "webrtc://live.mmcuav.cn/hls/shouguang01",
        },
      ],
      list: [{ name: this.$t('home.fourSplitScreen')}, { name: this.$t('home.nineSplitScreen')}],
      name: this.$t('home.fourSplitScreen'),
      uav_status: "LAND",
      uav_status_options: [
        {
          value: "LAND",
          label: this.$t('home.safeLanding'), //"安全降落",
        },
        {
          value: "AUTO_RTL",
          label:  this.$t('home.returnFlightMode'),//"返航模式",
        },
        {
          value: "AUTO_MISSION",
          label: this.$t('home.flightRouteMode'), //"航线模式",
        },
        {
          value: "POSITION",
          label:  this.$t('home.GPSMode'), //"GPS模式",
        },
      ],
      collapse: true,
      flightInfo: {},
      show_ms: 1,
      onOperation: false,
      selectOperation: 0,
      isPause: false, // 暂时飞行
      videoList: [{ name: this.$t('home.no')}],
      timer:null,
      timeStr:null
    };
  },
  computed: {
    $uav() {
      return this.$store.state.uavApplications.uav;
    },
  },
  watch: {
    mqttDataSet(newVal) {
      if (this.$uav?.network == 1) {
        if (newVal) {
          this.$store.dispatch("uavApplications/updateByMQTT", newVal);
        }
      }
    },
  },
  async created() {
    this.getUav();
  },
  mounted() {
    // this.$store.dispatch("uavApplications/test", { open: true });
    this.$store.commit("uavApplications/setState", {
      key: "cesiumViewer",
      value: window.viewer,
    });
    setTimeout(() => {
      this.changeVideoModel(0);
    }, 1000);
  },
  beforeDestroy() {
    this.$store.dispatch("uavApplications/destroy");
    if (this.data && this.data.network == 1) {
      // 飞控中心链路
      this.$store.dispatch("MMCMQTT/end");
    } else {
      // 地面站链路
      this.$store.dispatch("MMCGroundStation/end");
    }
  },
  methods: {
    stopPropagationFn(event){
      event.stopPropagation();
      console.log("阻止事件冒泡",event);
    },
    changeOSD(item){
      item.isInfoShow = !item.isInfoShow
      this.$forceUpdate()
    },
    changeTime(time) {
      let str = time.toString();
      if (str.length == 1) {
        str = "0" + str;
      }
      return str;
    },
    changeTimeStr(time) {
      let str = time.toString();
      if (str.length == 1) {
        str = "0" + str;
      }
      return str;
    },
    addDataToArray(arr, targetLength, data) {
      if (arr.length < targetLength) {
        arr = arr.concat(Array(targetLength - arr.length).fill(data));
      }
      return arr;
    },
    onOperate(data) {
      // if (!this.openTest) {
      //     return;
      // }
      this.$bus.$emit("clickUavTab");
      this.$bus.$emit("changeUavName", data.name);
      this.$bus.$emit("changeFlightInfo", data);
      this.$store.commit("SET_CESIUM_STATE", true);
      try {
        if (this.$uav && this.$uav.hardId !== data.hardId) {
          const hardId = this.$uav.hardId;
          // 判断上一台飞机,属于飞控中心还是地面站, 执行对应的取消订阅操作
          if (this.$uav.network === 1) {
            this.$store.dispatch("MMCMQTT/unsubscribe", {
              topic: "PX4/RECEIVE/" + hardId,
              callback() {
                console.log("mqtt取消订阅成功", "PX4/RECEIVE/" + hardId);
              },
            });
          } else {
            this.$store.dispatch("MMCGroundStation/unsubscribe", hardId);
            console.log("地面站取消订阅成功", hardId);
          }
        }
      } catch (error) {}
      this.$store.dispatch("uavApplications/destroy");
      if (data.network == 1) {
        // 飞控中心链路
        this.$store.dispatch("MMCMQTT/subscribe", {
          topic: "PX4/RECEIVE/" + data.hardId,
          callback() {
            console.log("mqtt主题订阅成功", "PX4/RECEIVE/" + data.hardId);
          },
        });
      } else {
        // 地面站链路
        this.$store.dispatch("MMCGroundStation/subscribe", data.hardId);
        console.log("地面站主题订阅成功", data.hardId);
      }
      if (this.$route.fullPath !== "/uavApplications") {
        this.$store.commit("uavApplications/setState", {
          key: "uav",
          value: data,
        });
      }
    },
    async getUav() {
      let res = null;
      if (this.isLogin) {
        res = await videoWell.uavOnLine();
      } else {
        res = await videoWell.uavSimulation();
      }
      if (res.code == 200) {
        let data = res.data.filter((item) => item.streamUrlMessage);
        const videoList = data
          .map((item) => {
            let newitem = item.streamUrlMessage
              .map((k) => {
                if (k.streamType == "WEBRTC") {
                  if(k.streamUrl.includes('srs://')){
                    return {
                      url: coverStreamUrl(k.streamUrl, item.hardId),
                      label: "WebRtc",
                      brand: "SRS",
                      isInfoShow:false,
                    }
                  } else {
                    return {
                      url: coverStreamUrl(k.streamUrl, item.hardId),
                      label: "WebRtc",
                      brand: "liveNVR",
                      isInfoShow:false,
                    };
                  }
                  
                } else if (k.streamType == "QINGLIU") {
                  return {
                    url: coverStreamUrl(k.streamUrl, item.hardId),
                    label: "QingLiu",
                    isInfoShow:false,
                  };
                } else if (k.streamType == "FLV") {
                  return {
                    url: coverStreamUrl(k.streamUrl, item.hardId),
                    label: "flv",
                    brand: "liveNVR",
                    isInfoShow:false,
                  };
                } else {
                  return null;
                }
              })
              .reverse();
            newitem = newitem.filter(Boolean); // 过滤掉 null 项
            if (newitem.length > 0) {
              return { ...item, streamUrlMessage: newitem };
            }
          })
          .filter(Boolean); // 过滤掉 null 项
        const mock = { name: this.$t('home.no'), streamUrlMessage: [], hardId: 11 };
        const newVideoList = await this.addDataToArray(videoList, 9, mock);
        this.videoList = newVideoList.filter((item, i) => i < 6);
        console.log(this.videoList, "videoList");
        this.data = newVideoList[0];
        this.onOperate(newVideoList[0]);
      }
    },
    close() {
      // 取消选择
      this.$bus.$emit("clickUavTab");
      this.$bus.$emit("changeUavName", null);
      this.$bus.$emit("changeFlightInfo", null);
      this.openData = {};
      this.$store.dispatch("uavApplications/destroy");
      if (this.data && this.data.network == 1) {
        // 飞控中心链路
        this.$store.dispatch("MMCMQTT/unsubscribe", {
          topic: "PX4/RECEIVE/" + this.data.hardId,
          callback() {
            console.log("mqtt取消订阅成功", "PX4/RECEIVE/" + this.data.hardId);
          },
        });
      } else {
        // 地面站链路
        this.$store.dispatch("MMCGroundStation/unsubscribe", this.deviceHardId);
      }
    },
    async changeVideoModel(index) {
      this.itemIndex = index;
      this.close();
      let res = null;
      if (this.isLogin) {
        res = await videoWell.uavOnLine();
      } else {
        res = await videoWell.uavSimulation();
      }
      if (res.code == 200) {
        try {
          if (res.data[index]) {
            const data = JSON.parse(JSON.stringify(this.videoList[index]));
            this.$set(this.videoList, index, {
              ...data,
              currentOperator: res.data[index].currentOperator || 0,
            });
          }
        } catch (error) {
          console.log(error);
        }
      }
      this.data = this.videoList[index];
      this.onOperate(this.videoList[index]);
    },
  },
};
</script>
<style lang="scss" scoped>
.videoListbox {
  width: calc(100% - 447px);
  height: calc(100% - 40px);
  background: #000000 !important;
  border-radius: 4px;
  margin-left: 12px;
  margin-top: 18px;
  position: absolute;
  top: 28px;
  left: 434px;
  padding-left: 8px;

  .defaultBorder {
    border: 1px solid transparent;
    overflow: hidden;
  }

  ::v-deep {
    .size_type {
      display: none;
    }

    .videoListboxItem {
      position: relative;

      .interact-wrap {
        height: 41.5vh;
        transform: none !important;
      }

      .MMCPlayerStyle {
        position: unset !important;
      }
      .osdInfo{
         z-index: 40;
          display: none;
        }
  
      .play {
        opacity: 0;
      }
    }

    .videoListboxItem_J {
      position: relative;

      .interact-wrap {
        // width: 424px;
        height: 26.6vh;
        transform: none !important;
      }

      .play {
        position: absolute !important;
        opacity: 0;
      }

      .el-input__suffix {
        right: 105px;
      }
    }

    .cpt_video {
      width: 99.6%;
      height: 100%;
      position: unset;
      border: none;
   
      .video {
        display: flex;
        justify-content: space-between;
        background: #0000;
        .name {
          width: 80%;
          text-align: left;
          text-indent: 1rem;
          background: transparent;
          opacity: 0;
        }
      
        .video-wrapper {
          .alt {
            display: none;
          }
        }
       
      }

      .close {
        display: none;
      }
    }

    .el-select {
      margin-right: 30px;
      margin-top: 10px;
      margin-bottom: 5px;
      background: #3a4252;
      border-radius: 2px;
      border: 1px solid #3bc1e5;
    }
    .MMCPlayerStyle {
      //全屏按钮
      video::-webkit-media-controls-fullscreen-button {
        display: none;
      }
      //播放按钮
      video::-webkit-media-controls-play-button {
        display: none;
      }
      //进度条
      video::-webkit-media-controls-timeline {
        display: none;
      }
      //观看的当前时间
      video::-webkit-media-controls-current-time-display {
        display: none;
      }
      //剩余时间
      video::-webkit-media-controls-time-remaining-display {
        display: none;
      }
      //音量按钮
      video::-webkit-media-controls-mute-button {
        display: none;
      }
      video::-webkit-media-controls-toggle-closed-captions-button {
        display: none;
      }
      //音量的控制条
      video::-webkit-media-controls-volume-slider {
        display: none;
      }
      //所有控件
      video::-webkit-media-controls-enclosure {
        display: none;
      }
    }
  }

  .content {
    width: 100%;
    height: calc(100% - 20px);
    display: flex;
    flex-wrap: wrap;
    overflow-y: auto;
    align-content: flex-start;

    .videoListboxItem {
      width: 49.3%;
      height: 45.2%;
      background-color: black;
      margin-right: 5px;
    }

    .videoListboxItem_J {
      width: 32.7%;
      height: 29.2%;
      background-color: black;
      margin-right: 5px;
    }
  }
  ::v-deep {
    .el-input__inner {
      border-radius: 0;
    }
    .videoListboxItemActive {
      border: 1px solid red;
      overflow: hidden;
    }
    .defaultBorder:hover {
      overflow: hidden;
      .cpt_video {
        .play {
          opacity: 1 !important;
        }
      }

      .play {
        position: absolute !important;
       
      }
      .name {
          opacity: 1 !important;
        }
        .osdInfo{
          display:block
        }
    }
  }
}
</style>

<template>
  <div v-if="isShowHeader && !isIframe">
    <div class="cpt_home_header">
      <!-- <div class="cpt_home_header_logo" v-if="!isElectron"> -->
      <div v-if="!subplatFormStatus && !subplatformPermission" class="cpt_home_header_left" :class="{ preventClick: isPreventClick_ }">
        <div class="cpt_home_header_logo">
          <img
            style="width: 32px; height: 32px"
            src="@/assets/home/MSpaceLogo1.png"
          />
          <span class="logo">{{$t('tmj')}}</span>
        </div>
        <div class="cpt_home_header_list">
          <li v-for="item in listNav" :key="item.id" :class="curNavId === item.id ? 'selectItem' : ''"
            :pms="item.name"
            @click="handleClick(item, curNavId !== item.id)">
            <div class="info">{{item.name}}</div>
            <!-- <div class="bgImg"><img :src="menuitemBg"/></div> -->

            <!-- <div class="ht100">
              <img v-if="curNavId === item.id" class="image" :src="item.img" />
              <img v-else class="image" :src="item.defalutImg" />
            </div> -->
          </li>
        </div>
      </div>
      <div v-else class="cpt_home_header_left_subplatForm">
        <!-- <div class="left-menu-box">
          <div title="菜单" class="icon menu" @click="showMenu">
            <div class="menu-list" v-if="showMenuStatus">
              <div
                class="item-menu"
                v-for="item in menuList"
                :key="item.id"
                :title="item.name"
                @click="changeActive(item.id)"
              >
                {{ item.name }}
              </div>
            </div>
          </div>
          <div title="主页" class="icon home" @click="goBack"></div>
        </div>
        <div class="title-bg">
          <img src="@/assets/home/subplatForm/Top_Title_BG.png" />
        </div>-->
        <div class="cpt_home_header_logo">
          <img
            style="width: 32px; height: 32px"
            alt="#"
            crossorigin="anonymous"
            :src="subplatformInfo && subplatformInfo.icon"
          />
          <span class="logo">
            {{$t('tmj')}}{{(subplatformInfo && subplatformInfo.projectName) || $t('home.no')}}
          </span>
        </div>
        <div class="cpt_home_header_list">
          <li
            v-for="item in subplatformlistNav"
            :key="item.id"
            :class="subplatformId === item.id ? 'selectItem' : ''"
            :pms="item.name"
            class="subplatForm-item"
            @click="changeActive(item)"
          >
            <div class="subplatForm-info">
              {{ item.name}}
              <!-- <img v-if="subplatformId === item.id" class="images" :src="item.img" />
              <img v-else class="images" :src="item.defalutImg" /> -->
            </div>
          </li>
        </div>
      </div>
      <!-- <img class="cpt_home_header_img" src="~@/assets/image/TopUavAnima.png"> -->
      <!-- <div class="cpt_home_header_service" @click="serviceVisible=true">
          <img class="service-icon" src="../../../assets/image/header/icon-kefu.png" />
          <div class="text">客服</div>
      </div>-->
      <div
        class="cpt_home_header_right"
        :style="{ width: $uav && !subplatformPermission ? '36%' : null }"
      >
        <!-- v-if="$uav" -->
        <div v-show="$uav" class="uav_info">
          <div class="item_signal ml40" :title="$t('header.signalValue')" style="margin-top: 8px;">
            <img :src="networkImg" alt="#" />
            <div
              v-if="uavRealTimeData.signal"
              class="item_signal_type"
            >{{uavRealTimeData.signal.type45}}</div>
          </div>
          <!-- 分割线 -->
          <div class="item_line" />
          <div class="icon-box" :title="parachuteListType[parachute]">
            <img :src="parachuteList[parachute]" alt="#" />
          </div>
          <div class="item_line" />
          <div v-if="parachute != 1" class="icon-box" :title="parachuteStateType[parachuteState]">
            <img :src="parachuteStateList[parachuteState]" alt="#" />
          </div>
          <!-- 分割线 -->
          <div v-if="parachute != 1" class="item_line" />
          <div class="item_signal" :title="$t('header.uavGPSInformation')">
            <img :src="gpsImg" alt />
          </div>
          <div class="item_line" />

          <div class="satellite_signal" :title="$t('header.StarSearchNumber')">
            <img :src="weixing" alt />
            <span class="icon_title lh44">
              {{
              uavRealTimeData.gps.satelliteCount
              }}
            </span>
          </div>
          <!-- 分割线 -->
          <div class="item_line" />
          <div class="icon-box" :title="$t('header.RockerOnline')">
            <img :src="control" alt="#" />
          </div>
          <div class="item_line" />
          <el-select
            v-if="leftMenuAction != 1  && $uav && $uav.network === 1"
            v-model="network_type"
            style="width: 70px"
            :placeholder="$t('home.pleaseSelect')"
            @change="changeNetwork"
            size="mini"
          >
            <el-option
              v-for="item in network_type_options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
          <el-select
            v-if="leftMenuAction != 1  && $uav"
            v-model="uav_status"
            style="width: 93px"
            :placeholder="$t('home.pleaseSelect')"
            @change="changeMode"
            size="mini"
          >
            <el-option
              v-for="item in uav_status_options"
              :key="item.id"
              :label="item.label"
              :value="item.data"
              :disabled="item.disabled"
            />
          </el-select>
          <div class="item_line" />

          <img
            v-if="uavRealTimeData.rcState == 1"
            class="icon-box"
            :title="$t('header.InfieldAuthority')"
            style="width: 25px; height: 25px"
            src="~@/assets/within.svg"
          />
          <div v-else class="icon-box" :title="$t('header.FieldAuthority')">
            <img :src="remoteControl" alt="#" />
          </div>
          <div class="item_line" />
        </div>
        <!--  -->
        <el-badge
          v-if="username"
          :is-dot="false"
          :value="warningCount"
          :max="99"
          :hidden="warningCount == 0"
          class="item"
        >
          <div
            v-if="username"
            :title="$t('header.MessageCenter')"
            class="message-icon-wrap"
            @click="showMessageCenterClick"
          >
            <img src="@/assets/image/header/msg.png" />
            <!-- <div v-if="!!messageCount.unreadCount" class="dot">
              {{
                messageCount.unreadCount > 99 ? "99+" : messageCount.unreadCount
              }}
            </div>-->
          </div>
        </el-badge>
        <div class="user_info">
          <div v-if="username" style="margin-right: 5px">
            <img :src="user_img" alt />
          </div>
          <div v-if="username" class="user_info_text">
            <el-tooltip class="item" effect="dark" :content="username" placement="bottom">
              <span class="user_info_text_top">{{ username }}</span>
            </el-tooltip>

            <span class="user_info_text_bottom">{{ userRole }}</span>
          </div>
          <div v-else class="user_info_text" style="margin-right: 20px; cursor: pointer">
            <span @click="goLogin">{{$t('login.login')}}</span>
          </div>

          <el-dropdown
            v-if="username"
            trigger="click"
            class="user_info_dropdown"
            @command="handleCommand"
          >
            <div class="icon">
              <!-- <i class="el-icon-arrow-up" /> -->
              <i class="el-icon-arrow-down" />
            </div>
            <el-dropdown-menu slot="dropdown" style="width: 150px; text-align: center">
              <!-- <el-dropdown-item style="padding: 10px" command="countDown"
                >有效期剩余{{ Time }}</el-dropdown-item
              >-->
              <el-dropdown-item style="padding: 10px" command="downloadCenter">{{$t('home.downloadCenter')}}</el-dropdown-item>
              <el-dropdown-item style="padding: 10px" command="customerService">{{$t('home.customerService')}}</el-dropdown-item>
              <el-dropdown
                v-if="username"
                class="user_info_dropdown_children"
                placement="left"
                @command="handleCommand"
              >
                <div class="icon_children">
                  <div class="label">{{$t('home.systemVideo')}}</div>
                  <!-- <i class="el-icon-arrow-down" /> -->
                </div>
                <el-dropdown-menu
                  slot="dropdown"
                  style="width: 150px; text-align: center"
                  class="videoDemo"
                >
                  <el-dropdown-item style="padding: 10px" command="showVideoDemo">{{$t('home.demonstration')}}</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
              <el-dropdown-item
                v-if="userInfo.roleType !== 4"
                style="padding: 10px"
                command="backgroundLogin"
              >{{$t('home.backgroundManagement')}} </el-dropdown-item>
              <el-dropdown-item style="padding: 10px" command="logout">{{$t('home.exit')}}</el-dropdown-item>
              <!-- <el-dropdown-item command="setup">设置</el-dropdown-item>
              <el-dropdown-item command="mycenter">个人中心</el-dropdown-item>-->
            </el-dropdown-menu>
          </el-dropdown>

          <div v-if="isElectron" class="box">
            <div class="iconfont icon-zuixiaohua" @click="toMin" />
            <div
              class="iconfont"
              :class="isFull ? 'icon-huanyuan' : 'icon-zuidahua'"
              @click="toMax"
            />
            <div class="iconfont icon-guanbi1" @click="toClose" />
          </div>
        </div>
      </div>
    </div>
    <HeaderSearch
      v-if="isCesium && leftMenuAction != 1"
      :item-list="itemList"
      :style="searchStyle"
      :nav-id="curNavId"
    />

    <!-- <FlyModel   v-if="leftMenuAction != 1 && FlyModelFlag && $uav" /> -->
    <RegionalAnalysis v-if="leftMenuAction != 1 && active === 118" />
    <!-- <Analysis
      v-if="isCesium && leftMenuAction != 1 && active === 117"
    />
     <div v-if="curNavId !== null" class="total-bar">
      <span v-for="(item, i) in totalBarData" :key="i" class="total-item">{{ item.title }}：
        <span class="total-bar__num">{{ item.value }}</span>
        {{ item.unit }}
      </span>
    </div>-->
    <div v-if="serviceVisible" class="service-dialog">
      <div v-interact class="header">
        <span class="title">客服</span>
        <span class="iconfont icon-guanbi" @click="serviceVisible = false" />
      </div>
      <div class="body">
        <h2 class="hotline">客户服务热线：400-808-2021</h2>
        <div class="qr-box">
          <img src="../../../assets/home/qrcode3.png" alt />
          <div class="qr-text">客服微信二维码</div>
        </div>
        <h3 class="tips">打开微信扫一扫</h3>
      </div>
    </div>

    <!--消息中心-->
    <message-center v-if="messageCenterShow" :show="messageCenterShow" @close="messageCenterClose" />
  </div>
</template>

<script>
import HeaderSearch from "../header-search";
import FlyModel from "../flyModel";
import Analysis from "../analysis";
import RegionalAnalysis from "../regional-analysis";
import MessageCenter from "../message-center/index.vue";

import UavJson from "@/data/uav.json";
import FlyerJson from "@/data/flyer.json";
import serviceJson from "@/data/service.json";
import trainJson from "@/data/train.json";
import showJson from "@/data/show.json";
import ProjectJson from "@/data/project.json";
import { Utils } from "@/utils/cesium";
import Popup from "./methods/popup";
import { MessageAPI, Tg, User } from "@/api";
import { mapState, mapMutations } from "vuex";
import { clearCookie } from "@/utils";
import { permissionIds } from "@/utils/permission";

import PopupNew from "./methods/popupNew";
import imgUAV from "@/assets/headerIcon/uav.png";
import imgFlyer from "@/assets/headerIcon/flyer.png";
import imgProject from "@/assets/headerIcon/project.png";
import imgShow from "@/assets/headerIcon/show.png";
import imgTrain from "@/assets/headerIcon/train.png";
import imgService from "@/assets/headerIcon/service.png";
import downLoadGif from "@/assets/headerIcon/download_gif.png";
import downLoadIcon from "@/assets/headerIcon/download.png";
import icon2 from "@/assets/image/icons/2.png";
import icon3 from "@/assets/image/icons/3.png";
import icon4 from "@/assets/image/icons/4.png";
import icon5 from "@/assets/image/icons/5.png";
import icon6 from "@/assets/image/icons/6.png";
import icon7 from "@/assets/image/icons/7.png";
import alert from "@/assets/image/icons/alert.png";
import exitAlert from "@/assets/image/icons/exitAlert.png";
import control from "@/assets/image/icons/control.png";
import remoteControl from "@/assets/image/icons/remoteControl.png";
import weixing from "@/assets/image/icons/weixing.svg";
import user_img from "@/assets/image/icons/user.svg";
import parachuteState1 from "@/assets/image/icons/jingjie.png";
import parachuteState2 from "@/assets/image/icons/tuichujingjie.png";
import parachuteState3 from "@/assets/image/icons/kaisan.png";

import parachute1 from "@/assets/image/icons/zaixian.png";
import parachute2 from "@/assets/image/icons/weiqiyong.png";
import parachute3 from "@/assets/image/icons/weizaixian.png";
import parachute4 from "@/assets/image/icons/sanbaoweilianjie.png";
import parachute5 from "@/assets/image/icons/jianglasanguzhang.png";

// 主平台
import home from "@/assets/image/header/home.png";
import select_home from "@/assets/image/header/select_home.png";
import industry from "@/assets/image/header/industry.png";
import select_industry from "@/assets/image/header/select_industry.png";
import flight_Applications from "@/assets/image/header/flight_Applications.png";
import select_flight_Applications from "@/assets/image/header/select_flight_Applications.png";
import flight_management from "@/assets/image/header/flight_management.png";
import select_flight_management from "@/assets/image/header/select_flight_management.png";
import natural_resources from "@/assets/image/header/natural_resources.png";
import select_natural_resources from "@/assets/image/header/select_natural_resources.png";
import tree_3D from "@/assets/image/header/threeAppdefault.png";
import ON_tree_3D from "@/assets/image/header/threeApp.png";
// 子平台
import main_platform_on from "@/assets/image/header/main_platform_on.png";
import main_platform_off from "@/assets/image/header/main_platform_off.png";
import task_management_on from "@/assets/image/header/task_management_on.png";
import task_management_off from "@/assets/image/header/task_management_off.png";
import flight_applications_on from "@/assets/image/header/flight_applications_on.png";
import flight_applications_off from "@/assets/image/header/flight_applications_off.png";
import flight_management_on from "@/assets/image/header/flight_management_on.png";
import flight_management_off from "@/assets/image/header/flight_management_off.png";



import homeIcon from "@/assets/navibarMenu/home.png";
import homeIconActive from "@/assets/navibarMenu/home-active.png";
import menuitemBg from "@/assets/navibarMenu/menuitemBg.png";
import menuitemBgActive from "@/assets/navibarMenu/menuitemBg-active.png";


import { Control_API } from "@/api";
const { VUE_APP_BASE_BACK_URL } = process.env;
const data_srouce = {
  uav: null,
  flyer: null,
  service: null,
  train: null,
  show: null,
  project: null,
  download: null,
};
const GeoJson = {
  // uav: UavJson,
  // flyer: FlyerJson,
  // service: serviceJson,
  // train: trainJson,
  // show: showJson
  uav: [],
  flyer: [],
  service: [],
  train: [],
  show: [],
  download: [],
  project: ProjectJson,
};
const iconMap = new Map([
  [2, icon2],
  [3, icon3],
  [4, icon4],
  [5, icon5],
  [6, icon6],
  [1, icon7],
]);

export default {
  name: "Homeheader",
  components: {
    FlyModel,
    HeaderSearch,
    Analysis,
    RegionalAnalysis,
    MessageCenter,
  },
  props: {},
  inject: ["get_flight_info"],
  data() {
    return {
      menuitemBg,
      uav_status: "",
      uav_status_options: [
        { id: 11, data: "AUTO_RTL", label: this.$t('home.returnFlightMode'), disabled: false },
        {
          id: 12,
          data: "AUTO_FOLLOW_TARGET",
          label: this.$t('home.跟随模式'),
          disabled: false,
        },
        { id: 8, data: "HOLD", label: this.$t('home.保持模式'), disabled: false },
        { id: 2, data: "ALTITUDE", label: this.$t('home.定高模式'), disabled: false },
        { id: 3, data: "POSITION", label: this.$t('home.定点模式'), disabled: false },
        { id: 4, data: "AUTO_MISSION", label: this.$t('home.flightRouteMode'), disabled: false },
        { id: 1, data: "MANUAL", label: this.$t('home.手动模式'), disabled: true },
        { id: 5, data: "ACRO", label: this.$t('home.特技模式'), disabled: true },
        { id: 6, data: "OFF_BOARD", label: this.$t('home.板外模式'), disabled: true },
        { id: 7, data: "STABILIZED", label: this.$t('home.自稳模式'), disabled: true },
        { id: 9, data: "LAND", label: this.$t('home.safeLanding'), disabled: true },
        { id: 10, data: "TAKE_OFF", label: this.$t('home.起飞模式'), disabled: true },
        { id: 0, data: "UNKNOWN", label: this.$t('home.离线状态'), disabled: true },
      ],
      network_type: 0, //网络类型
      network_type_options: [
        {
          value: 0,
          label: "自动",
        },
        {
          value: 1,
          label: "图传",
        },
        {
          value: 2,
          label: "专网",
        },
        {
          value: 3,
          label: "公网",
        },
      ],
      parachuteList: {
        0: parachute1,
        1: parachute2,
        2: parachute3,
        3: parachute4,
        4: parachute5,
      },
      
      parachuteListType: {
        0: this.$t('header.ParachuteIsNormal'), // "伞包正常",
        1: this.$t('header.ParachuteEnabled'),// "伞包未启用",
        2: this.$t('header.ControllerOutline'),// "控制器不在线",
        3: this.$t('header.parachuteDisconnected'),// "伞包未连接",
        4: this.$t('header.parachuteFailure'),// "降落伞故障",
      },
      parachuteStateList: {
        1: parachuteState1,
        2: parachuteState2,
        3: parachuteState3,
      },
      
      parachuteStateType: {
        1: this.$t('header.parachuteInAlert'), //"降落伞进入警戒",
        2: this.$t('header.parachuteOutAlert'), //"降落伞退出警戒",
        3: this.$t('header.parachuteOut'), //"降落伞开伞",
      },
      subplatformPermission: localStorage.getItem("subplatform-permission"),
      subplatformInfo: JSON.parse(localStorage.getItem("subplatformInfo")),
      subplatformId: 1,
      main_platform_on,
      main_platform_off,
      task_management_on,
      task_management_off,
      flight_applications_on,
      flight_applications_off,
      flight_management_on,
      flight_management_off,
      flight_management,
      natural_resources,
      flight_Applications,
      home,
      weixing,
      user_img,
      control,
      remoteControl,
      alert,
      exitAlert,
      isLogin: localStorage.getItem("tmj_token"),
      menuList: [
        {
          id: 1,
          name: "飞行任务",
        },
        {
          id: 2,
          name: "飞行管理",
        },
      ],
      serviceVisible: false,
      del_real: false,
      username: "",
      listNav: [
        {
          // flightApplication: 'Flight Application',
          // flightManagement: 'Flight Management',
          // industryApplication: "Tndustry Application",
          // resourceManagement: "Resource Management",
          // threeDimensionalApplications: "Three Dimensional Applications",

          name: this.$t('home.home'), //"首页",
          icon: "iconfont  icon-wurenji",
          id: 1,
          value: "home",
          isShow: false,
          popups: [],
          // img: imgUAV
          img: select_home,
          defalutImg: home,
        },
        {
          pid: 454,
          name: this.$t('home.flightApplication'), //"飞行应用",
          icon: "iconfont  icon-feishou",
          id: 2,
          value: "flyer",
          isShow: false,
          popups: [],
          // img: imgFlyer
          img: select_flight_Applications,
          defalutImg: flight_Applications,
        },
        {
          pid: 545,
          name: this.$t('home.flightManagement'),//"飞行管理",
          icon: "iconfont  icon-fuwuwangdian",
          id: 3,
          value: "manage",
          isShow: false,
          popups: [],
          // img: imgService
          img: select_flight_management,
          defalutImg: flight_management,
        },
        {
          pid: 452,
          name: this.$t('home.industryApplication'), //"行业应用",
          icon: "iconfont  icon-wurenji",
          id: 4,
          value: "uav",
          isShow: false,
          popups: [],
          // img: imgUAV
          img: select_industry,
          defalutImg: industry,
        },
        {
          pid: 768,
          name:  this.$t('home.resourceManagement'), //"资源管控",
          icon: "iconfont  icon-fuwuwangdian",
          id: 5,
          value: "service",
          isShow: false,
          popups: [],
          // img: imgService
          img: select_natural_resources,
          defalutImg: natural_resources,
        },
        {
          pid: 567,
          name:  this.$t('home.threeDimensionalApplications'),//"三维应用",
          icon: "iconfont icon-yunhangxiangmu",
          id: 6,
          value: "service",
          isShow: false,
          popups: [],
          // img: imgService
          img: ON_tree_3D,
          defalutImg: tree_3D,
        },
        // {
        //   name: '培训网点',
        //   icon: 'iconfont  icon-peixunwangdian',
        //   id: 4,
        //   value: 'train',
        //   isShow: false,
        //   popups: [],
        //   img: imgTrain
        // },
        // {
        //   name: '展示网点',
        //   icon: 'iconfont  icon-zhanshiwangdian',
        //   id: 5,
        //   value: 'show',
        //   isShow: false,
        //   popups: [],
        //   img: imgShow
        // },
        // {
        //   name: '运行项目',
        //   icon: 'iconfont  icon-yunhangxiangmu',
        //   id: 6,
        //   value: 'project',
        //   isShow: false,
        //   popups: [],
        //   img: imgProject
        // },
        // {
        //   name: '下载中心',
        //   id: 7,
        //   isShow: false,
        //   popups: [],
        //   value: 'download',
        //   img: downLoadGif, // 动态图片
        //   defalutImg: downLoadIcon// 静态图片
        // }
      ],

      subplatformlistNav: [
        {
          name: this.$t('home.mainPlatform'), //"主平台",
          icon: "iconfont  icon-wurenji",
          id: 0,
          value: "home",
          isShow: false,
          popups: [],
          // img: imgUAV
          img: main_platform_on,
          defalutImg: main_platform_off,
        },
        {
          pid: 452,
          name: this.$t('home.taskManagement'), //"任务管理", 
          icon: "iconfont  icon-wurenji",
          id: 1,
          value: "task",
          isShow: false,
          popups: [],
          // img: imgUAV
          img: task_management_on,
          defalutImg: task_management_off,
        },
        {
          pid: 454,
          name: this.$t('home.flightApplication'), //"飞行应用",
          icon: "iconfont  icon-feishou",
          id: 3,
          value: "flyer",
          isShow: false,
          popups: [],
          // img: imgFlyer
          img: select_flight_Applications,
          defalutImg: flight_Applications,
        },
        {
          pid: 545,
          name: this.$t('home.flightManagement'),//"飞行管理",
          icon: "iconfont  icon-fuwuwangdian",
          id: 2,
          value: "manage",
          isShow: false,
          popups: [],
          // img: imgService
          img: select_flight_management,
          defalutImg: flight_management,
        },
        {
          pid: 567,
          name: this.$t('home.threeDimensionalApplications'), //"三维应用",
          icon: "iconfont icon-yunhangxiangmu",
          id: 4,
          value: "service",
          isShow: false,
          popups: [],
          // img: imgService
          img: ON_tree_3D,
          defalutImg: tree_3D,
        },
      ],
      curNavId: 1, // 当前选择的nav
      isShowPopup: false,
      point_id: 0,
      isRePopup: false,
      isElectron: false,
      isFull: false,
      isTest: false,
      itemList: [],
      userRole: "",
      isLogin: false,
      Time: null,
      totalBarData: [
        /* { title: '总共', value: '345', unit: '台' }, { title: '总共', value: '345', unit: '台' } */
      ], // 统计条数据 {title: '总共', value: '345', unit: '台'}
      userInfo: {},
      loginState: null,
      messageCenterShow: false,
      messageCount: {
        readCount: 0,
        unreadCount: 0,
      },
      isShowHeader: true, // 是否全局显示header
      warningCount: 0,
      init_lng: 112.576667,
      init_lat: 23.175833,
      init_alt: 5000,
    };
  },
  computed: {
    ...mapState({
      isCesium: "isCesium",
      active: "active",
      // openLeftNavSilder: 'openLeftNavSilder'
      openRightNavSilder: "openRightNavSilder",
    }),
    ...mapState(["openLeftNavSilder", "leftMenuAction", "isIframe"]),
    ...mapState("subplatform", ["subplatFormStatus"]),
    ...mapState("uavApplications", ["uavRealTimeData"]),
    isPreventClick_() {
      return this.$store.state.uavApplications.isPreventClick;
    },

    // 刷新预警数
    refreshWarningCount() {
      return this.$store.state.uavApplications.refreshWarningCount;
    },
    currentEagle() {
      return this.$store.state.uavApplicationsEagleNest.currentEagle;
    },
    $uav() {
      return this.$store.state.uavApplications.uav;
    },
    parachute() {
      return this.$store.state.uavApplications.uavRealTimeData.parachute;
    },
    parachuteState() {
      return this.$store.state.uavApplications.uavRealTimeData.parachuteState;
    },
    FlyModelFlag() {
      return this.$store.state.uavApplications.FlyModelFlag;
    },
    searchStyle() {
      let right = "4px";
      if (this.openRightNavSilder) {
        right = "362px";
      } else {
        right = "4px";
      }
      return {
        right,
      };
    },
    /**
     * 网络环境
     */
    network() {
      let { uavRealTimeData } = this;
      let name = null;
      if (uavRealTimeData && uavRealTimeData.link) {
        for (let i = 0; i < uavRealTimeData.link.length; i++) {
          const k = uavRealTimeData.link[i];
          if (k.priority == 2 && k.using) {
            return "专网";
          } else if (k.priority == 3 && k.using) {
            return "公网";
          } else if (k.priority == 1 && k.using) {
            return "图传";
          }
        }
      }
      return name || "离线";
    },
    /**
     * 网络信号
     */
    networkSignal() {
      let { uavRealTimeData } = this;
      if (this.network === "公网") {
        if (
          uavRealTimeData &&
          uavRealTimeData.signal &&
          uavRealTimeData.signal.sqe45
        ) {
          if (uavRealTimeData.signal.sqe45 === -1) {
            return 0;
          }
          return uavRealTimeData.signal.sqe45 || 0;
        }
        return 0;
      } else if (this.network === "专网") {
        if (
          uavRealTimeData &&
          uavRealTimeData.signal &&
          uavRealTimeData.signal.sqe14
        ) {
          if (uavRealTimeData.signal.sqe14 === -1) {
            return 0;
          }
          return uavRealTimeData.signal.sqe14 || 0;
        }
        return 0;
      }
    },
    networkImg() {
      return this.$uav
        ? require(`@/assets/image/icons/5g-${this.networkSignal || 0}.svg`)
        : "";
    },
    /**
     * gps信号
     */
    gpsSignalCount() {
      let { uavRealTimeData } = this;
      if (uavRealTimeData && uavRealTimeData.gps.satelliteCount) {
        let count = parseInt(uavRealTimeData.gps.satelliteCount / 6);
        if (count >= 5) {
          count = 5;
        } else {
          count += 1;
        }
        return uavRealTimeData.gps.satelliteCount ? count : 0;
      }
      return 0;
    },
    gpsImg() {
      return this.$uav
        ? require(`@/assets/image/icons/GPS-${this.gpsSignalCount || 0}.svg`)
        : "";
    },
  },
  watch: {
    subplatformId(newVal) {
      console.log(newVal, "newVal");
      if (newVal) {
        this.flyto();
      }
    },
    refreshWarningCount(val) {
      if (val) {
        console.log("刷新");
        this.warningCountFn();
      }
    },
    curNavId(val) {
      if (!val) {
        this.itemList = [];
      }
      this.flyto();
      this.toLogin(val);
    },

    uavRealTimeData(val) {
      this.network_type = val?.priority;
      this.uav_status = val?.flightMode;
    },
    subplatFormStatus(val) {
      this.subplatformPermission = localStorage.getItem(
        "subplatform-permission"
      );
      this.subplatformInfo = JSON.parse(
        localStorage.getItem("subplatformInfo")
      );
    },
  },
  // 生命周期 - 创建完成（访问当前this实例）
  created() {
    const newList = [];
    this.listNav.map((item) => {
      if (this.permissionListFn(item)) {
        newList.push(item);
      }
    });
    
    this.listNav = newList;
    this.userInfo = JSON.parse(window.localStorage.getItem("user_info"));
    if (!this.subplatformPermission) {
      const listValue = this.listNav.filter(
        (item) => item.id === this.$route.params.active
      )[0];
      if (listValue) {
        this.handleClick(listValue);
        this.$emit("changeList", listValue.value);
      } else if (this.$route.path !== "/uavApplications") {
        if (this.$route.query.active === undefined) {
          this.handleClick(this.listNav[0]);
        } else {
          const find = this.listNav.find(
            (item) => Number(item.id) === Number(this.$route.query.active)
          );
          if (find) {
            this.handleClick(find);
          }
        }
      }
    } else {
      const subplatformlistValue = this.subplatformlistNav.filter(
        (item) => item.id === this.$route.params.active
      )[0];
      if (subplatformlistValue) {
        this.changeActive(subplatformlistValue);
        this.$emit("changeList", subplatformlistValue.value);
      } else if (this.$route.path !== "/uavApplications") {
        this.changeActive(this.subplatformlistNav[1]);
      }
    }
    if (
      this.$route.path === "/uavApplications" &&
      !this.subplatformPermission
    ) {
      this.curNavId = 2;
    } else if (
      this.$route.path === "/uavApplications" &&
      this.subplatformPermission
    ) {
      this.subplatformId = 3;
    }
    this.getMessageCount();
  },
  // 生命周期 - 挂载完成（访问DOM元素）
  async mounted() {
    // 获取未读信息
    this.warningCountFn();
    this.click_billboard();
    if (window.localStorage.getItem("user_info")) {
      const data = JSON.parse(window.localStorage.getItem("user_info"));
      this.username = data.userName;
      this.userRole = data.orgName;
      await this.asyncGetPersonalCenter(data);
    }

    this.getBrowserIsElctron();

    this.$bus.$on("closeListNav", () => {
      this.closeList();
    });
    // 刷新消息数量
    this.$bus.$on("refreshMsgCount", () => {
      this.getMessageCount();
    });

    // 模拟点击某一项
    this.$bus.$on("clickHeaderNav", (data) => {
      this.handleClick(this.listNav[data.index], data.isShow, data.list);
    });
    //     if (localStorage.getItem('subplatform-permission')) {
    //   //先显示飞行任务
    //   this.$store.commit("subplatform/SET_SUBPLAT_FORM_ACTIVE", 1)
    //   this.$store.commit('CHANGE_ACTYIVE', 10)
    //   //左侧模块-左侧菜单栏 0为子平台
    //   this.$store.commit('SET_LEFT_MENU_ACTION', 0)
    //   //子平台显示隐藏
    //   this.$store.commit("subplatform/SET_SUBPLAT_FORM_STATUS", true)
    // }
  },
  methods: {
    ...mapMutations([
      "SET_CESIUM_STATE",
      "SET_OPEN_LEFT_NAV_SILDER",
      "SET_PERMISSION",
      "SET_LEFT_MENU_ACTION",
      "SET_SUBPLAT_FORM_STATUS",
      "SET_SHOW_DEMO",
      "SET_PROJECT_DATA",
    ]),
    flyto() {
      const viewer = window.viewer;
      if (viewer) {
        viewer.scene.camera.flyTo({
          destination: Cesium.Cartesian3.fromDegrees(
            this.init_lng,
            this.init_lat,
            this.init_alt
          ),
        });
      }
    },
    // 无人机接管更新状态
    async updateTakeOver() {
      await Control_API.updateTakeOver(this.$uav.id);
    },
    // 更改网络类型
    changeNetwork(value) {
      this.$store.dispatch("MMCMQTT/publish", {
        topic: "PX4/OBTAIN/" + this.$uav.hardId,
        data: {
          type: 770,
          data: this.network_type,
        },
        callback: () => {
          this.$message.success("指令已下发");
        },
      });
    },
    changeMode(mode) {
      this.$store.dispatch("MMCMQTT/publish", {
        topic: "PX4/OBTAIN/" + this.$uav.hardId,
        data: {
          type: this.$store.state.MMCMQTT.orders.无人机模式切换,
          data: mode,
        },
        callback: () => {
          this.$message.success("指令已下发");
        },
      });
      this.updateTakeOver();
    },
    async warningCountFn() {
      const res = await Tg.warningCount();
      if (res.code == 200) {
        this.warningCount = res.data.total;
      }
      console.log(res, "res------------------------");
    },
    permissionListFn(item) {
      // 获取权限组成的id 数组
      const ids = permissionIds();
      if (item.name == this.$t('home.home')) {
        return true;
      } else {
        if (ids.length > 0) {
          return ids.some((id) => id === item.pid);
        } else {
          // 未登录时把所有某块展示出来
          return true;
        }
      }
    },
    active_demo() {
      this.SET_SHOW_DEMO(true);
    },
    changeActive(item) {
      console.log(item.name, "item.name ");
      if (item.name !== this.$t('home.mainPlatform')) {
        this.SET_OPEN_LEFT_NAV_SILDER(false);
      }
      if (item.name != this.$t('home.threeDimensionalApplications')) {
        this.$store.commit("subplatform/SET_TOTAL_MAPS", 1);
        this.$store.commit("CHANGE_ACTYIVE", -1);
      }
      this.subplatformId = item.id;
      this.$emit("changeList", item.value);
      this.$store.commit("subplatform/SET_SUBPLAT_FORM_ACTIVE", item.id);
      if (item.name === this.$t('home.mainPlatform')) {
        return this.goBack();
      } else if (item.name === this.$t('home.flightApplication')) {
        this.$router.push({
          name: "uavApplications",
          params: { active: item.id },
        });
      } else {
        this.$router.push({ name: "homeView", params: { active: item.id } });
      }
    },
    goBack() {
      localStorage.removeItem("subplatform-permission");
      localStorage.removeItem("subplatformInfo");
      // 去除无人机列表名称
      this.SET_PROJECT_DATA("");
      this.subplatformPermission = localStorage.getItem(
        "subplatform-permission"
      );
      // 先显示飞行任务
      this.$store.commit("subplatform/SET_SUBPLAT_FORM_ACTIVE", 1);
      this.$store.commit("CHANGE_ACTYIVE", -1);
      // 左侧模块-左侧菜单栏 0为子平台
      this.$store.commit("SET_LEFT_MENU_ACTION", 1);
      // 子平台显示隐藏
      this.$store.commit("subplatform/SET_SUBPLAT_FORM_STATUS", false);
    },
    getTimer(times) {
      const days = times / (1000 * 60 * 60 * 24);
      const hours = (times % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60);
      const minutes = (times % (1000 * 60 * 60)) / (1000 * 60);
      const seconds = (times % (1000 * 60)) / 1000;
      if (days >= 1) {
        this.Time = `${days.toFixed(0)}天`;
      } else if (days < 1 && hours >= 1) {
        this.Time = `${hours.toFixed(0)}小时`;
      } else if (hours < 1 && minutes >= 1) {
        this.Time = `${minutes.toFixed(0)}分钟`;
      } else if (minutes < 1 && seconds >= 1) {
        this.Time = `${seconds.toFixed(0)}秒`;
      }
    },
    async asyncGetPersonalCenter(data) {
      this.isLogin = !!window.localStorage.getItem("tmj_token");
      const res = await User.PersonalCenter({ id: data.id });
      if (res.code === 200) {
        const times = new Date(res.data.validityDate).getTime() - Date.now();
        this.getTimer(times);
      }
    },
    // 获取无人机数据
    getUavList() {
      return new Promise(async (resolve, reject) => {
        const res = await Tg.getUavList({ accountId: 1 });
        if (res.code === 200) {
          const data = res.data.map((item) => {
            item.carNo = item.flyInfo.flyName;
            item.device_name = item.flyInfo.flyType;
            item.img_type = item.flyInfo.imageType;
            item.lat = item.latitude;
            item.lon = item.longitude;
            // 健康状态获取
            let val1 = 0;
            let val2 = 0;
            for (const key in item.healthStatuInfo) {
              if (item.healthStatuInfo[key] === 1) {
                val1++;
              }
              val2++;
            }
            item.healthStatus = `${val1}/${val2}`;
            return item;
          });
          resolve(data);
        }
      });
    },
    // 获取飞手数据
    getFlyHandList() {
      return new Promise(async (resolve, reject) => {
        const res = await Tg.getFlyHandList({ accountId: 1 });
        // console.log("========", data)
        if (res.code === 200) {
          const data = res.data.map((item) => {
            item.lon = item.longitude;
            item.lat = item.latitude;
            item.phone_num = item.phone_num || item.phone;
            return item;
          });
          resolve(data);
        }
      });
    },
    // 获取网点 数据
    getNetworkList(name) {
      return new Promise(async (resolve, reject) => {
        let res = {};
        if (name === "service") {
          // 服务网点
          res = await Tg.getServicePointListMap({ accountId: 1 });
        } else if (name === "train") {
          // 培训网点
          res = await Tg.getTrainPointListMap({ accountId: 1 });
        } else if (name === "show") {
          // 展示网点
          res = await Tg.getExhibitionPointListMap({ accountId: 1 });
        }
        // const res = await Tg.getFlyHandList({ accountId: 1 })
        // // console.log("========", data)
        if (res.code === 200) {
          const data = res.data.map((item) => {
            item.name =
              item.trainName || item.serviceName || item.exhibitionName;
            item.lon = item.longitude;
            item.lat = item.latitude;
            item.address =
              item.trainAddress ||
              item.serviceAddress ||
              item.exhibitionAddress;
            return item;
          });
          resolve(data);
        }
      });
    },
    getRunProjectListSimpleList() {
      // console.log('getRunProjectListSimple')
      return new Promise(async (resolve, reject) => {
        const res = await Tg.getRunProjectListSimple({ accountId: 1 });
        if (res.code === 200) {
          const data = res.data;
          resolve(data);
        }
      });
    },
    goLogin() {
      this.$router.push("/login");
    },
    closeList() {
      this.listNav.forEach((item, index) => {
        if (data_srouce[item.value]) {
          data_srouce[item.value].show = false;
        }
      });
      this.curNavId = null;
    },
    getBrowserIsElctron() {
      const userAgent = navigator.userAgent;
      const isElectron = userAgent.includes("Electron");
      this.isElectron = isElectron;
    },
    handleCommand(command) {
      if (command == "logout") {
        this.goBack();
        this.SET_OPEN_LEFT_NAV_SILDER(false);
        window.localStorage.removeItem("tmj_token");
        window.localStorage.removeItem("user_info");
        this.SET_PERMISSION([]);
        clearCookie();
        this.$message.success(this.$t('login.logoutMessage'));
        this.$bus.$emit("reloadHome");
      } else if (command === "customerService") {
        this.serviceVisible = true;
      } else if (command === "downloadCenter") {
        this.handleClick({ value: "download" }, false);
      } else if (command === "showVideoDemo") {
        this.active_demo();
      } else if (command === "backgroundLogin") {
        // 跳转后台
        const token = window.localStorage.getItem("tmj_token");
        const userInfo = window.localStorage.getItem("user_info");
        const url = `${VUE_APP_BASE_BACK_URL}?t_=${encodeURIComponent(
          token
        )}&userInfo=${encodeURIComponent(userInfo)}`;
        window.sender = window.open(url); // url：B系统的地址
      }
    },
    toLogin(id) {
      // 未登录跳转登录页
      this.loginState = !!window.localStorage.getItem("tmj_token");
      if (!this.loginState && id !== 1) {
        return this.$router.push("/login");
      }
    },
    handleClick(e) {
      const token = window.localStorage.getItem("tmj_token");
      if (e.name !==  this.$t('home.home')) {
        if (!token) {
          this.SET_OPEN_LEFT_NAV_SILDER(false);
          window.localStorage.removeItem("tmj_token");
          window.localStorage.removeItem("user_info");
          this.SET_PERMISSION([]);
          clearCookie();
          this.$store.commit("subplatform/SET_SUBPLAT_FORM_STATUS", false);
          this.$router.replace("/login");
          this.$bus.$emit("reloadHome");
          return;
        }
      }
      if (e.name != this.$t('home.threeDimensionalApplications')) {
        this.$store.commit("subplatform/SET_TOTAL_MAPS", 1);
        this.$store.commit("CHANGE_ACTYIVE", -1);
      } else if (e.name == this.$t('home.threeDimensionalApplications')) {
        this.$store.commit("CHANGE_ACTYIVE", -1);
      }
      this.SET_OPEN_LEFT_NAV_SILDER(false);
      this.curNavId = e.id;
      this.$emit("changeList", e.value);
      // this.toLogin(e.id)
      if (e.value === "download") {
        return;
      }
      this.SET_LEFT_MENU_ACTION(e.id);
      if (e.name === this.$t('home.flightApplication')) {
        this.$router.push({
          name: "uavApplications",
          params: { active: e.id },
        });
      } else {
        this.$router.push({ name: "homeView", params: { active: e.id } });
      }
    },
    click_billboard() {
      const viewer = window.viewer;

      const scene = viewer.scene;
      const _this = this;

      const handler = new Cesium.ScreenSpaceEventHandler(viewer.scene.canvas);

      handler.setInputAction(async (movement) => {
        const pickedLabel = viewer.scene.pick(movement.endPosition);
        // let pickedLabel = movement;
        var pick = new Cesium.Cartesian2(
          movement.endPosition.x,
          movement.endPosition.y
        );
        if (Cesium.defined(pickedLabel)) {
          // 获取点击位置坐标
          // let cartesian = viewer.scene.globe.pick(
          //   viewer.camera.getPickRay(pick),
          //   viewer.scene
          // );
          var cartesian = viewer.scene.pickPosition(movement.endPosition);
          // let cartesian = pick;
          if (pickedLabel.id) {
            try {
              const data = JSON.parse(pickedLabel.id._id);
              // let isRePopup = false;
              // console.log("data....", data);
              if (!_this.point_id) {
                _this.point_id = data.id;
                _this.isRePopup = true;
              } else if (_this.point_id != data.id) {
                _this.isRePopup = true;
                // _this.point_id = 0;
              }
              if (data.entityType == "header_point") {
                _this.listNav.forEach((item) => {
                  if (item.isShow) {
                    if (!_this.isShowPopup) {
                      _this.isShowPopup = true;
                      item.popups.forEach((v) => {
                        v.close();
                      });
                      if (_this.isRePopup) {
                        item.popups.push(
                          new Popup({
                            viewer: viewer,
                            geometry: cartesian,
                            data: data,
                            del_real: data.isShow,
                          })
                        );
                      }
                    } else {
                      setTimeout(() => {
                        _this.isShowPopup = false;
                      }, 100);
                    }
                  }
                });
              }
            } catch (error) {}
          }
        } else {
          this.point_id = 0;
          _this.listNav.forEach((item) => {
            if (item.popups && item.popups.length > 0) {
              item.popups.forEach((v) => {
                v.close();
              });
            }
          });
        }
      }, Cesium.ScreenSpaceEventType.MOUSE_MOVE);
    },
    toMin() {
      const electron = window.electron;
      const ipcRenderer = electron?.ipcRenderer;
      ipcRenderer && ipcRenderer.send("min-app");
    },
    toMax() {
      const electron = window.electron;
      const ipcRenderer = electron?.ipcRenderer;
      this.isFull = !this.isFull;
      ipcRenderer && ipcRenderer.send("max-app", this.isFull);
    },
    toClose() {
      const electron = window.electron;
      const ipcRenderer = electron?.ipcRenderer;
      ipcRenderer && ipcRenderer.send("close-app");
    },
    // 获取消息数量
    getMessageCount() {
      MessageAPI.getMsgCount().then(({ data }) => {
        if (data) {
          this.messageCount = data;
        }
      });
    },
    //  消息中心弹窗
    showMessageCenterClick() {
      this.messageCenterShow = true;
    },
    messageCenterClose() {
      this.messageCenterShow = false;
    },
  },
};
</script>
<style scoped lang="scss">
.cpt_home_header_right {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .uav_info {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-around;
    gap: 8px;

    .item_signal {
      color: #00f5ff;
      position: relative;

      .item_signal_type {
        position: absolute;
        left: -1px;
        top: -8px;
        font-size: 12px;
        font-weight: bold;
      }
    }

    .modal {
      color: red;
    }

    .item_line {
      width: 1px;
      height: 32px;
      background: linear-gradient(
        180deg,
        rgba(55, 63, 191, 0) 0%,
        #5ddae0 47%,
        rgba(55, 63, 191, 0) 100%
      );
    }

    .icon-box {
      width: 24px;
      height: 24px;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .satellite_signal {
      position: relative;
      color: #00f5ff;

      .icon_title {
        position: absolute;
        right: 0;
        top: 0;
        font-size: 12px;
      }
    }

    .airplane_mode {
      display: flex;
      align-items: center;

      .label {
        font-size: 14px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #ffffff;
        line-height: 20px;
      }
    }
  }
}
.message-icon-wrap {
  margin: 0 10px;
  position: relative;
  cursor: pointer;
  img {
    width: 24px;
    height: 24px;
  }
  .dot {
    width: 22px;
    height: 22px;
    background-color: rgba(255, 30, 0, 0.97);
    border-radius: 50%;
    position: absolute;
    color: #fff;
    font-size: 10px;
    position: absolute;
    right: 0;
    top: 0;
    text-align: center;
    line-height: 20px;
    transform: translate(25%, -40%);
  }
}
.user_info {
  display: flex;
  /* position: absolute;
  right: 0;
  top: 0; */
  // width: 128px;
  height: 48px;
  align-items: center;

  .user_info_text {
    font-family: PingFangSC-Semibold;
    font-size: 14px;
    color: #ffffff;
    letter-spacing: 0;
    font-weight: 600;
    display: flex;
    flex-direction: column;
  }

  .user_info_text_top {
    font-family: PingFangSC-Semibold;
    font-size: 14px;
    color: #ffffff;
    letter-spacing: 0;
    font-weight: 600;
    text-align: left;
    width: 60px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    cursor: pointer;
  }

  .user_info_text_bottom {
    font-family: PingFangSC-Semibold;
    font-size: 12px;
    color: #7f828a;
    letter-spacing: 0;
    font-weight: 400;
    text-align: left;
  }

  .user_info_dropdown {
    margin-right: 10px;
  }

  .user_info_dropdown_children {
    /* margin-right: 10px; */
  }
}

.videoDemo.el-popper {
  margin-right: 60px;
}

.icon {
  margin-left: 10px;
  background: #3a4252;
  border-radius: 2px;
  display: flex;
  width: 24px;
  height: 24px;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  color: #8287a0;

  i {
    width: 24px;
    height: 12px;
    text-align: center;
    font-size: 8px;
  }
}

.icon_children {
  position: relative;
  left: 50px;
  // background: #3a4252;
  border-radius: 2px;
  display: flex;
  width: 24px;
  height: 24px;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  color: #8287a0;

  .label {
    width: 80px;
    color: #fff;
    position: absolute;
    right: 20px;
    // top: 0;
    white-space: nowrap;
  }
}

.cpt_home_header_list li {
  position: relative;
  /* width: 128px; */
    width: 168px;
    height: 48px;
  // text-align: center;
  // line-height: 48px;
  // border: 1px solid transparent;
  // box-sizing: border-box;
  //  transform: skew(30deg);


  border-left: 1px solid #34DFFE ;
  // border-right: 1px solid #34DFFE;
  border-radius: 0 15px 0 15px;
  transform: skew(30deg);
  font-size: 18px;
  color: #FFFFFF;
  &:last-child{
    border-right: 1px solid #34DFFE;
  }
  &.selectItem{
     color: #34DFFE;
     position: relative;
     cursor: pointer;
    &::after{
      content: '';
      width: 64px;
      height: 3px;
      background: #34DFFE;
      border-radius: 2px;
      position: absolute;
      bottom: 2px;
      left: 50%;
      transform: translate(-50%);
    }
  }


  .info{
    text-align: center;
    position: relative;
    transform: skew(-30deg);
  }
  .bgImg{
    position: absolute;
    top: 0;
    left: 0 ;
    height: 100%;
    img{
      max-width: 100%;
      height: auto;
    }

  }


  
  // &::before{
  //   content: '';
  //   width: 5px;
  //   height: 100%;
  //   background: radial-gradient(circle at 100% 0, transparent,transparent 20px, red 10px);
  //   bottom: 0;
  //   right: -20px;
  // }
  // &::after {
  //   content: "";
  //   position: absolute;
  //   width: 20px;
  //   height: 100%;
  //   background-color: red;  //动态颜色
  //   left: -10px;
  //   top: 0;
  //   transform: skew(-20deg);
  // }

  .cpt_home_header_list_item_img {
    width: 24px;
    height: 24px;
    margin-right: 20px;
  }

  .iconfont {
    font-size: 18px;
    margin-right: 20px;
  }

  .image {
    width: 245px;
    height: 48px;
    transform: skew(-30deg);
  }
  .images {
    width: 245px;
    height: 48px;
    transform: skew(-30deg);
  }
}

.service-dialog {
  width: 534px;
  height: 430px;
  background: rgba(19, 21, 24, 0.7);
  box-shadow: inset 0 1px 8px 0 #2883b3,
    inset 0 4px 48px 0 rgba(53, 76, 147, 0.5),
    inset 0 16px 48px 0 rgba(93, 173, 255, 0.15);
  border-radius: 4px;
  border: 1px solid #3bc1e5;
  position: absolute;
  z-index: 100;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);

  .header {
    height: 32px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 8px;
    border-radius: 4px 4px 0 0;

    .title {
      font-family: MicrosoftYaHei;
      font-size: 14px;
      color: #ebf5ff;
    }

    .iconfont {
      font-size: 18px;
      color: #b3bbc5;
      cursor: pointer;

      &:hover {
        opacity: 0.7;
      }
    }
  }

  .body {
    text-align: center;
    height: calc(100% - 85px);
    margin: 0 auto;
    box-sizing: border-box;

    .hotline {
      margin-top: 30px;
      font-size: 20px;
      font-family: "PingFangSC-Medium PingFang SC";
      font-weight: 500;
      color: #52cdff;
    }

    .qr-box {
      margin: 26px auto 26px auto;
      width: 220px;
      height: 240px;

      .bg {
        width: 200%;
        height: 200%;
        background: red;
      }

      img {
        width: 172px;
        height: 172px;
        margin-top: 22px;
        margin-bottom: 5px;
      }

      .qr-text {
        text-align: center;
        font-size: 14px;
        color: #e3eeff;
      }
    }

    .tips {
      font-size: 18px;
      font-weight: 500;
      color: #f8f8f8;
    }
  }
}

.cpt_home_header_list .cpt_home_header_list_bg {
  background-image: linear-gradient(
      180deg,
      rgba(80, 171, 255, 0.08) 0%,
      rgba(80, 171, 255, 0.08) 48%,
      rgba(80, 171, 255, 0) 50%
    ),
    linear-gradient(
      180deg,
      rgba(0, 144, 255, 0.5) 2%,
      rgba(26, 45, 64, 0.03) 17%,
      rgba(30, 32, 38, 0) 51%,
      rgba(0, 43, 83, 0) 86%,
      rgba(0, 140, 244, 0.5) 99%
    );
  border: 1px solid #50abff;
  box-sizing: border-box;
  box-shadow: inset 0 0 0 0 rgba(255, 255, 255, 0.05), inset 0 0 0 0 #1e2026;
}

.cpt_home_header_img {
  position: absolute;
  right: calc(100vw * 0.085);
  top: 0;
  height: 100%;
}

@media (max-width: 1500px) {
  .cpt_home_header_img {
    right: 120px;
  }
}

@media (max-width: 1340px) {
  .cpt_home_header_img {
    display: none;
  }
}

// .cpt_home_header_list li:hover {
//   color: #ffffff;
//   opacity: 1;
// }

.cpt_home_header {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background: #2c313b;
  display: flex;
  align-items: center;
  height: 48px;
  justify-content: space-between;
  z-index: 1;
}

.cpt_home_header_left {
  display: flex;
  align-items: center;
}

.cpt_home_header_left_subplatForm {
  display: flex;
  align-items: center;

  .left-menu-box {
    width: 100px;
    display: flex;
    justify-content: space-around;

    .icon {
      cursor: pointer;
      width: 32px;
      height: 32px;
    }

    .menu {
      position: relative;
      background: url("~@/assets/home/subplatForm/icon/menu.png") no-repeat;

      .menu-list {
        position: absolute;
        left: 10px;
        bottom: -80px;
        z-index: 2;
        border-radius: 2px;
        overflow: hidden;

        .item-menu {
          width: 106px;
          height: 36px;
          line-height: 36px;
          color: #fff;
          text-align: center;
          background: #2a3243;
          box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5),
            inset 0px 0px 0px 0px #3e485c;

          &:hover {
            background: #1890ff;
          }
        }
      }
    }

    .home {
      background: url("~@/assets/home/subplatForm/icon/home.png") no-repeat;
    }
  }

  .subplatForm-item{
    text-align: center;
    .subplatForm-info{
      transform: skew(-30deg);
    }
  }
}

.title-bg {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 0;
}

.cpt_home_header_logo {
  position: relative;
  margin-left: 0px;
  /* width: 122px; */
  line-height: 48px;
  display: flex;
  // justify-content: space-evenly;
  align-items: center;
  // background: #00ffff;
  margin-left: 20px;

  .logo_img {
    position: absolute;
    left: 10px;
    // background: red;
  }

  .logo {
    min-width: 134px;
    height: 28px;
    font-size: 18px;
    font-family: AppleSystemUIFont;
    color: #ffffff;
    line-height: 28px;
    margin-left: 16px;
  }
}

.list_item {
  width: 128px;
}

.cpt_home_header_list {
  font-family: MicrosoftYaHei-Bold;
  font-size: 14px;
  color: #989a9c;
  letter-spacing: 0;
  display: flex;
  /* width: 858px; */
  justify-content: space-around;
  // left: 200px;
  // position: absolute;
  /* background: url('~@/assets/image/header/select_all.png') no-repeat; */
  /* background-size: cover; */
  margin-left: 10px;

  & :hover {
    color: #ffffff;

    .download-img {
      opacity: 1;
    }
  }

  .download-img {
    opacity: 0.7;
  }

  .list_item:hover {
    // background: #00ffff;
    font-family: MicrosoftYaHei-Bold;
    font-size: 14px;
    color: #ffffff;
    letter-spacing: 0;
    font-weight: 700;
    opacity: 1;
    background: #323c59;
  }

  /* .selectItem {
    background-image: linear-gradient(
        180deg,
        rgba(80, 171, 255, 0.15) 0%,
        rgba(80, 171, 255, 0.15) 48%,
        rgba(80, 171, 255, 0.08) 49%,
        rgba(80, 171, 255, 0.08) 100%
      ),
      linear-gradient(
        180deg,
        rgba(0, 144, 255, 0.5) 2%,
        rgba(26, 45, 64, 0.03) 10%,
        rgba(30, 32, 38, 0) 51%,
        rgba(0, 43, 83, 0) 91%,
        rgba(0, 140, 244, 0.5) 99%
      );
    border: 1px solid #50abff;
    // box-shadow: inset 0 0 0 0 rgba(255, 255, 255, 0.05), inset 0 0 0 0 #1e2026;
    border-radius: 8px;
    span,
    i {
      color: #fff;
    }

    .icon_text {
      font-weight: bold;
    }
  } */
}

.cpt_home_header_list li {
  flex: 1;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cpt_home_header_service {
  cursor: pointer;
  position: absolute;
  right: 120px;
  display: flex;
  align-items: center;

  .service-icon {
    width: 21px;
    height: 21px;
    margin-right: 14px;
    display: block;
  }

  .text {
    color: #52cdff;
    font-size: 15px;
  }
}

.box::v-deep {
  float: right;
  display: flex;

  .el-icon-plus {
    width: 48px;
    height: 48px;
    color: white;
    text-align: center;
    line-height: 48px;
    -webkit-app-region: no-drag;
  }

  .icon-zuixiaohua,
  .icon-zuidahua,
  .icon-guanbi1,
  .icon-huanyuan {
    width: 48px;
    height: 48px;
    font-size: 22px;
    color: #fff;
    text-align: center;
    line-height: 48px;
    -webkit-app-region: no-drag;
  }
}

/* @import url(); 引入css类 */
</style>

<style lang="scss">
// 重要元素弹窗
.bx-popup-ctn {
  // min-width: 100px;0
  // max-width: 230px !important;
  // height: 200px;
  width: 226px !important;
  border: 1px solid #50abff !important;
  border-radius: 4px !important;
  position: absolute;
  // z-index: 999;
  background: #1e2026;
}

.bx-popup-header-ctn-nm {
  padding: 0 10px;
  box-sizing: border-box;
  max-width: 230px;
  font-size: 14px;
  padding-left: 10px;
  height: 31px;
  line-height: 31px;
  color: #a4ff64;
  width: 100%;
  opacity: 0.9;
  background: #1e2026;
}

.bx-popup-header-ctn {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
  box-sizing: border-box;
  max-width: 230px;
  opacity: 0.9;
  background: #1e2026;
  height: 31px;
  line-height: 31px;
  width: 100%;
  // background: url('~@/assets/headerIcon/titleLBG@svg.svg') no-repeat;
}

.header-ctn1 {
  font-family: MicrosoftYaHei-Bold;
  font-size: 14px;
  color: #a4ff64;
  letter-spacing: 0;
  font-weight: 700;
  width: 72%;
  height: 100%;

  &:hover {
    cursor: pointer;
  }
}

.header-ctn2 {
  font-family: MicrosoftYaHei-Bold;
  font-size: 14px;
  color: #ffffff;
  letter-spacing: 0;
  font-weight: 700;
  width: 28%;
  height: 100%;
  padding-left: 4px;

  &:hover {
    cursor: pointer;
  }
}

.titleRBG {
  background: url("~@/assets/headerIcon/titleRBG@svg.svg") no-repeat;
}

.titleLBG {
  background: url("~@/assets/headerIcon/titleLBG@svg.svg") no-repeat;
}

.bx-popup-content-ctn {
  max-width: 230px;
  opacity: 0.9;
  background: #1e2026;
  // border: 1px solid #50ABFF;
  font-family: MicrosoftYaHei;
  font-size: 12px;
  color: #b3bbc5;
  letter-spacing: 0;
  font-weight: 400;
  box-sizing: border-box;
  padding: 10px 5px;
  height: 130px;
}

.li {
  display: flex;
  margin: 5px 0;

  // word-break: break-word;
  .text {
    max-width: 70%;
  }
}

#content-ctn2 {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

#content-ctn2-right {
  width: calc(100% - 120px);
}

#content-ctn2-left {
  // width: 96px;
  border: 1px solid #000000;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 10px;
  box-sizing: border-box;
}

.bx-popup-tip-container {
  width: 40px;
  height: 20px;
  position: absolute;
  left: 50%;
  margin-left: -20px;
  // margin-top: 10px;
  overflow: hidden;
  pointer-events: none;
}

.bx-popup-tip {
  width: 17px;
  // background: #0c3150;
  background: #1e2026;
  border: 1px solid #50abff;
  height: 17px;
  padding: 1px;
  margin: -10px auto 0;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.el-icon-close {
  color: #8aabd4;
  font-size: 16px;
  position: absolute;
  right: 12px;
  top: 20px;
  transform: translateY(-50%);
  cursor: pointer;

  &:hover {
    color: #fff;
  }
}
</style>

<style lang="scss">
// 用户无人机弹窗
.popup-new-body {
  background: rgba(19, 21, 24, 0.7);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5), inset 0 1px 4px 0 #2e63dc,
    inset 0 4px 48px 0 rgba(53, 76, 147, 0.5),
    inset 0 16px 48px 0 rgba(93, 173, 255, 0.15);
  border-radius: 8px;
  width: 1029px !important;
  height: 655px !important;
  position: absolute;
  // display: flex;
  // flex-direction: column;
}

.popup-new-header {
  // padding: 0 10px;
  // box-sizing: border-box;
  // max-width: 230px;
  font-size: 14px;
  padding-left: 10px;
  height: 48px;
  line-height: 48px;
  color: #a4ff64;
  border-bottom: 1px solid #494949;
  // width: 100%;
  // opacity: 0.9;
  // background: #1e2026;
}

// 内容
.popup-new-content {
  height: 90%;
  margin: 6px;
  // width: 100%;
  display: flex;
  border-radius: 2px;
  //   .popup-new-content-left {
  //     height: 100%;
  //     width: 35%;
  //     background: rgba(0, 0, 0, 0.95);
  //     border-radius: 2px;
  //     .up {
  //       img {
  //         margin-top: 100px;
  //       }
  //     }
  //   }
  //   .popup-new-content-right {
  //     height: 100%;
  //     width: 64%;
  //     margin-left: 8px;
  //     display: flex;
  //     flex-direction: column;
  //     color: #fff;
  //     position: relative;
  //     .popup-new-content-right-up {
  //       height: 31%;
  //       span {
  //         margin-left: 8px;
  //       }
  //       .content {
  //         .tb-box {
  //           margin-top: 4px;
  //           box-sizing: border-box;
  //           height: 100%;
  //           padding: 0 8px;
  //           border-collapse: collapse;
  //           display: flex;
  //           .tb-hd-box {
  //             flex: 1;
  //             height: calc(100% - 36px);
  //             background: #1e2026;
  //             background-image: linear-gradient(
  //               180deg,
  //               rgba(255, 255, 255, 0.05) 0%,
  //               rgba(255, 255, 255, 0) 100%
  //             );
  //             box-shadow: inset 1px 1px 0 0 #000000;
  //             display: flex;
  //             flex-direction: column;
  //             justify-content: space-between;
  //             .tb-hd {
  //               height: 36px;
  //               line-height: 36px;
  //               text-align: center;
  //               font-family: MicrosoftYaHei;
  //               font-size: 12px;
  //               color: #b3bbc5;
  //               letter-spacing: 0;
  //               font-weight: 400;
  //               border-left: 1px solid #020310;
  //               border-bottom: 1px solid #020310;
  //             }
  //             // border-right: 1px solid #000;
  //           }

  //           .tb-bd-box {
  //             flex: 1;
  //             height: calc(100% - 36px);
  //             background: #020310;
  //             box-sizing: border-box;
  //             flex-direction: column;
  //             justify-content: space-between;
  //             .td {
  //               // border-left: 1px solid #000;
  //               border-bottom: 1px solid #000;
  //               display: flex;
  //               align-items: center;
  //               justify-content: center;
  //               padding: 10px 1px;
  //               text-align: center;
  //               word-break: break-word;
  //               box-sizing: border-box;
  //               font-family: MicrosoftYaHei;
  //               font-size: 12px;
  //               color: #c6ccd4;
  //               letter-spacing: 0;
  //               font-weight: 400;
  //               .iconfont {
  //                 font-size: 16px;
  //                 color: #b3bbc5;
  //                 margin-left: 8px;
  //               }
  //               border-left: 1px solid #000;
  //             }
  //             &:nth-child(odd) {
  //               background: #14151a;
  //             }
  //           }
  //         }
  //       }
  //     }
  //     .popup-new-content-right-down {
  //       height: 67%;
  //       width: 100%;
  //       margin-top: 6px;
  //       position: absolute;
  //       bottom: 0px;
  //       span {
  //         margin-left: 8px;
  //       }
  //       .content {
  //         .tb-box {
  //           margin-top: 4px;
  //           box-sizing: border-box;
  //           height: 100%;
  //           padding: 0 8px;
  //           border-collapse: collapse;
  //           display: flex;
  //           .tb-hd-box {
  //             flex: 1;
  //             height: calc(100% - 36px);
  //             background: #1e2026;
  //             background-image: linear-gradient(
  //               180deg,
  //               rgba(255, 255, 255, 0.05) 0%,
  //               rgba(255, 255, 255, 0) 100%
  //             );
  //             box-shadow: inset 1px 1px 0 0 #000000;
  //             display: flex;
  //             flex-direction: column;
  //             justify-content: space-between;
  //             .tb-hd {
  //               height: 36px;
  //               line-height: 36px;
  //               text-align: center;
  //               font-family: MicrosoftYaHei;
  //               font-size: 12px;
  //               color: #b3bbc5;
  //               letter-spacing: 0;
  //               font-weight: 400;
  //               border-left: 1px solid #020310;
  //               border-bottom: 1px solid #020310;
  //             }
  //             // border-right: 1px solid #000;
  //           }

  //           .tb-bd-box {
  //             flex: 1;
  //             height: calc(100% - 36px);
  //             background: #020310;
  //             box-sizing: border-box;
  //             flex-direction: column;
  //             justify-content: space-between;
  //             .td {
  //               // border-left: 1px solid #000;
  //               background: #0e1b16;
  //               border-bottom: 1px solid #000;
  //               display: flex;
  //               align-items: center;
  //               justify-content: center;
  //               padding: 10px 1px;
  //               text-align: center;
  //               word-break: break-word;
  //               box-sizing: border-box;
  //               font-family: MicrosoftYaHei;
  //               font-size: 12px;
  //               color: #68dd76;
  //               letter-spacing: 0;
  //               font-weight: 400;
  //               .iconfont {
  //                 font-size: 16px;
  //                 color: #b3bbc5;
  //                 margin-left: 8px;
  //               }
  //               border-left: 1px solid #000;
  //             }
  //             &:nth-child(odd) {
  //               background: #14151a;
  //             }
  //           }
  //         }
  //       }
  //     }
  //   }
}

// 底部箭头
.bx-popup-new-tip-container {
  width: 40px;
  height: 20px;
  position: absolute;
  left: 50%;
  margin-left: -20px;
  margin-top: -30px;
  overflow: hidden;
  pointer-events: none;
}

.bx-popup-new-tip {
  width: 17px;
  // background: #0c3150;
  background: rgba(22, 29, 45, 0.7);
  border: 1px solid #04d5da;
  height: 17px;
  padding: 1px;
  margin: -10px auto 0;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.total-bar {
  position: fixed;
  top: 56px;
  left: 50%;
  transform: translate(-50%, 0);
  background-image: linear-gradient(
    270deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.35) 50%,
    rgba(0, 0, 0, 0) 100%
  );
  width: 600px;
  line-height: 1;
  padding: 18px 0 11px;
  text-align: center;
  font-family: MicrosoftYaHei;
  font-size: 14px;
  color: #b3bbc5;
  letter-spacing: 0;
  font-weight: 400;

  .total-item {
    margin-left: 40px;

    &:first-child {
      margin-left: 0;
    }
  }

  .total-bar__num {
    font-size: 20px;
    color: #1eb9fa;
  }
}
</style>
<style>
.el-badge__content.is-fixed {
  right: 46px;
  top: 3px;
}
</style>
